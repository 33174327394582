export default `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect />
<circle cx="9.5" cy="18.5" r="1.5" transform="rotate(-90 9.5 18.5)" fill="currentColor"/>
<circle cx="9.5" cy="6.5" r="1.5" transform="rotate(-90 9.5 6.5)" fill="currentColor"/>
<circle cx="9.5" cy="12.5" r="1.5" transform="rotate(-90 9.5 12.5)" fill="currentColor"/>
<circle cx="15.5" cy="18.5" r="1.5" transform="rotate(-90 15.5 18.5)" fill="currentColor"/>
<circle cx="15.5" cy="6.5" r="1.5" transform="rotate(-90 15.5 6.5)" fill="currentColor"/>
<circle cx="15.5" cy="12.5" r="1.5" transform="rotate(-90 15.5 12.5)" fill="currentColor"/>
</svg>
`;
