import { UserDTO } from './userDataService';
import http from './axiosClient';

export type UserDTOPickClickCounter = Pick<UserDTO, 'currentMonthClicks' | 'lastMonthClicks' | 'lastClickTimestamp'>;

export function useStatsService() {
  function updateChatbotClickCount(id: number) {
    return http.put(`/api/stats/click/${id}`);
  }
  async function getChatbotClickCount(id: number) {
    const res = await http.get<UserDTOPickClickCounter>(`/api/stats/click/${id}`);
    return res.data;
  }

  return {
    updateChatbotClickCount,
    getChatbotClickCount,
  };
}
