export default `<svg  viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.0484 6.94694C15.4402 5.73372 15.636 5.12711 16 5.12711C16.364 5.12711 16.5598 5.73372 16.9516 6.94694L18.7763 12.5976C18.8842 12.9317 18.9382 13.0988 19.0697 13.1946C19.2013 13.2903 19.3768 13.2903 19.7279 13.2903H24.911C26.1831 13.2903 26.8192 13.2903 26.9312 13.6363C27.0432 13.9823 26.5278 14.3551 25.4971 15.1006L21.089 18.2888C20.8049 18.4943 20.6628 18.597 20.6123 18.7515C20.5618 18.906 20.6157 19.0728 20.7235 19.4065L22.5606 25.0924C22.948 26.2915 23.1417 26.8911 22.8486 27.1043C22.5555 27.3175 22.0447 26.9485 21.0233 26.2104L16.5857 23.0038C16.3031 22.7996 16.1617 22.6975 16 22.6975C15.8383 22.6975 15.6969 22.7996 15.4143 23.0038L10.9767 26.2104C9.95525 26.9485 9.44455 27.3175 9.15141 27.1043C8.85827 26.8911 9.05199 26.2915 9.43942 25.0924L11.2765 19.4065C11.3843 19.0728 11.4382 18.906 11.3877 18.7515C11.3372 18.597 11.1951 18.4943 10.911 18.2888L6.50294 15.1006C5.47217 14.3551 4.95679 13.9823 5.0688 13.6363C5.18081 13.2903 5.81687 13.2903 7.08899 13.2903H12.2721C12.6232 13.2903 12.7987 13.2903 12.9303 13.1946C13.0618 13.0988 13.1158 12.9317 13.2237 12.5976L15.0484 6.94694Z" fill="url(#paint0_linear_597_21187)" stroke="url(#paint1_linear_597_21187)" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_597_21187" x1="4" y1="16" x2="43.4286" y2="16" gradientUnits="userSpaceOnUse">
<stop stop-color="#4F46E5"/>
<stop offset="0.734393" stop-color="#FFA7BB"/>
</linearGradient>
<linearGradient id="paint1_linear_597_21187" x1="4" y1="16" x2="43.4286" y2="16" gradientUnits="userSpaceOnUse">
<stop stop-color="#4F46E5"/>
<stop offset="0.734393" stop-color="#FFA7BB"/>
</linearGradient>
</defs>
</svg>

`;
