import qs from 'qs';
import http, { StrapiDTO, StrapiResponseDTO, useAxiosUtils } from './axiosClient';
import { UserDTO } from './userDataService';
import { useHandleError } from './logErrors';

/*
 * All Analytics come with option to use JWT headers or using API Key
 * Use the getAuth() function with the withApiKey prop
 */

export interface Analytic {
  user?: StrapiDTO<UserDTO> | number;
  createdAt?: Date;
  eventDataType?: 'string' | 'number' | 'json' | 'date';
  eventName?: string;
  eventValue?: string;
  lastDateEvent?: Date;
  updatedAt?: Date;
}

interface ClickCounterJSON {
  [year: number]: {
    [month: number]: number;
  };
}

export function useAnalyticService() {
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();
  const { getHeaders, getApiToken } = useAxiosUtils();
  const { throwError } = useHandleError();

  const getAuth = (withApiKey: boolean) => {
    if (withApiKey) {
      return getApiToken();
    }
    return getHeaders();
  };

  async function upsertAnalytic(
    analyticName: string,
    userID: number,
    object: Analytic,
    withApiKey = false
  ): Promise<StrapiDTO<Analytic> | unknown> {
    let analytic;
    try {
      analytic = await getAnalyticFilteredByName(analyticName, userID, withApiKey);
      if (!analytic) {
        return await createAnalytic(object, userID, withApiKey);
      }
      if (analytic.attributes.eventDataType === 'number' && analytic.attributes.eventValue && object.eventValue) {
        const newNumberValue = parseInt(analytic.attributes.eventValue) + parseInt(object.eventValue);
        const newObject: Partial<Analytic> = { ...object, eventValue: newNumberValue.toString() };
        return await updateAnalytic(analytic.id, newObject, withApiKey);
      } else {
        return await updateAnalytic(analytic.id, object, withApiKey);
      }
    } catch (error) {
      return error;
    }
  }

  async function createAnalytic(object: Analytic, UserId: number, withApiKey = false): Promise<StrapiDTO<Analytic>> {
    const payload = {
      data: {
        user: UserId,
        ...object,
      },
    };
    const resp = await http.post<StrapiResponseDTO<StrapiDTO<Analytic>>>(`/api/analytics`, payload, getAuth(withApiKey));
    return resp.data.data;
  }
  async function updateAnalytic(id: number, object: Analytic, withApiKey = false): Promise<StrapiDTO<Analytic>> {
    const resp = await http.put<StrapiResponseDTO<StrapiDTO<Analytic>>>(
      `/api/analytics/${id}`,
      { data: { ...object } },
      getAuth(withApiKey)
    );
    return resp.data.data;
  }
  async function getAnalytic(id: number): Promise<StrapiDTO<Analytic>> {
    const resp = await http.get<StrapiResponseDTO<StrapiDTO<Analytic>>>(`/api/analytics/${id}`, getHeaders());
    return resp.data.data;
  }

  async function getAnalyticFilteredByNameStartWith(analyticName: string, userID: number, withApiKey = false) {
    const query = qs.stringify(
      {
        filters: {
          user: {
            id: {
              $eq: userID,
            },
          },
          eventName: {
            $startsWith: analyticName,
          },
        },
        populate: '*',
      },
      { encodeValuesOnly: true }
    );
    const resp = await http.get<StrapiResponseDTO<Array<StrapiDTO<Analytic>>>>(`/api/analytics?${query}`, getAuth(withApiKey));
    if (resp.data.data && resp.data.data.length) {
      return resp.data.data;
    }
    return undefined;
  }

  async function getAnalyticFilteredByNameEndsWith(analyticName: string, userID: number, withApiKey = false) {
    const query = qs.stringify(
      {
        filters: {
          user: {
            id: {
              $eq: userID,
            },
          },
          eventName: {
            $endsWith: analyticName,
          },
        },
        populate: '*',
      },
      { encodeValuesOnly: true }
    );
    const resp = await http.get<StrapiResponseDTO<Array<StrapiDTO<Analytic>>>>(`/api/analytics?${query}`, getAuth(withApiKey));
    if (resp.data.data && resp.data.data.length) {
      return resp.data.data;
    }
    return undefined;
  }

  async function getAnalyticFilteredByName(analyticName: string, userID: number, withApiKey = false) {
    const query = qs.stringify(
      {
        filters: {
          user: {
            id: {
              $eq: userID,
            },
          },
          eventName: {
            $eq: analyticName,
          },
        },
        populate: '*',
      },
      { encodeValuesOnly: true }
    );
    const resp = await http.get<StrapiResponseDTO<Array<StrapiDTO<Analytic>>>>(`/api/analytics?${query}`, getAuth(withApiKey));
    if (resp.data.data && resp.data.data.length) {
      return resp.data.data[0];
    }
    return undefined;
  }

  async function addClickAnalytic(analyticName: string, userID: number) {
    try {
      const analytic = await getAnalyticFilteredByName(analyticName, userID, true);
      if (!analytic) {
        await createNewAnalytic(analyticName, userID);
      } else {
        await updateExistingAnalytic(analytic);
      }
    } catch (error) {
      throwError(error);
    }
  }

  async function updateExistingAnalytic(analytic: StrapiDTO<Analytic>) {
    if (!analytic.attributes.eventValue) {
      return;
    }

    const JSONdata: ClickCounterJSON = JSON.parse(analytic.attributes.eventValue);
    const updatedJSONdata = getUpdatedJSONValue(JSONdata);

    const payload: Partial<Analytic> = {
      ...analytic,
      lastDateEvent: now,
      eventValue: JSON.stringify(updatedJSONdata),
    };
    try {
      await updateAnalytic(analytic.id, payload, true);
    } catch (error) {
      throwError(error);
    }
  }

  function getUpdatedJSONValue(JSONdata: ClickCounterJSON) {
    let currentMonthJsonValue;
    const currentYearJsonValue = JSONdata[currentYear];
    if (currentYearJsonValue) {
      currentMonthJsonValue = JSONdata[currentYear][currentMonth];
    }

    if (!currentYearJsonValue) {
      return {
        ...JSONdata,
        [currentYear]: {
          [currentMonth]: 1,
        },
      };
    }
    if (!currentMonthJsonValue) {
      return {
        ...JSONdata,
        [currentYear]: {
          ...JSONdata[currentYear],
          [currentMonth]: 1,
        },
      };
    }
    return {
      ...JSONdata,
      [currentYear]: {
        ...JSONdata[currentYear],
        [currentMonth]: JSONdata[currentYear][currentMonth] + 1,
      },
    };
  }

  async function createNewAnalytic(analyticName: string, userId: number) {
    const eventValue = {
      [currentYear]: {
        [currentMonth]: 1,
      },
    };
    const payload: Partial<Analytic> = {
      eventDataType: 'json',
      eventName: analyticName,
      eventValue: JSON.stringify(eventValue),
      lastDateEvent: now,
    };
    await createAnalytic(payload, userId, true);
  }

  return {
    createAnalytic,
    updateAnalytic,
    getAnalytic,
    getAnalyticFilteredByName,
    getAnalyticFilteredByNameStartWith,
    upsertAnalytic,
    addClickAnalytic,
    getAnalyticFilteredByNameEndsWith,
  };
}
