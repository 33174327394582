import axios, { AxiosError, AxiosResponse } from 'axios';
import { envs } from '../env';
import { useUserStore } from '../stores/userStore';

const VITE_API_URL = envs.VITE_API_URL;
const VITE_CONTACT_ME_FORM_API_TOKEN = envs.VITE_CONTACT_ME_FORM_API_TOKEN;

//declare header type
export type Header = {
  headers: {
    Authorization: string;
    'Content-Type'?: string;
  };
};

export interface StrapiDTO<T> {
  id: number;
  attributes: T;
}

export interface StrapiResponse<T> {
  data: T;
  meta: {
    pagination: {
      limit: number;
      start: number;
      total: number;
    };
  };
}

export type StrapiResponseDTO<T> = StrapiResponse<T> & AxiosResponse<T>;

export interface StrapiError {
  data: null;
  error: {
    status: number;
    name: string;
    message: string;
    details?: {
      errors?: {
        message: string;
        name: string;
        path: string[];
      }[];
    };
  };
}

export interface StrapiObjectBody<T> {
  data: T;
}

export function useAxiosUtils() {
  const userStore = useUserStore();

  const getHeaders = (): Header | undefined => {
    const token = userStore.loggedInInfo?.jwt;
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${userStore.loggedInInfo?.jwt}`,
        },
      };
    }
    return undefined;
  };

  const getHeadersFormData = (): Header | undefined => {
    const token = userStore.loggedInInfo?.jwt;
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${userStore.loggedInInfo?.jwt}`,
          'Content-Type': 'multipart/form-data',
        },
      };
    }
    return undefined;
  };

  const getApiToken = (): Header => {
    return {
      headers: {
        Authorization: `Bearer ${VITE_CONTACT_ME_FORM_API_TOKEN}`,
      },
    };
  };

  return {
    getHeaders,
    getApiToken,
    getHeadersFormData,
  };
}

const onResponseFulfilled = (response: AxiosResponse) => {
  return response;
};

const onResponseRejected = (error: Error) => {
  if (!(error instanceof AxiosError)) {
    return Promise.reject(error);
  }
  if (error.response?.status === 401) {
    useUserStore().logout();
  }
  return Promise.reject(error);
};

const axiosClient = axios.create({
  baseURL: VITE_API_URL as string,
  headers: {
    'Content-type': 'application/json',
  },
});

axiosClient.interceptors.response.use(onResponseFulfilled, onResponseRejected);

export default axiosClient;
