import posthog from 'posthog-js';
import { UserDTO } from './userDataService';

export type CustomPosthogEvent = 'dmo_step_completed' | 'scheduled_follow_up';

type EventProperties = {
  dmo_step_completed: {
    stepId: string;
    stepName: string;
  };
  scheduled_follow_up: {
    leadId: number;
    followUpDate: string;
  };
};

export function usePosthog() {
  const identifyPosthog = (user: UserDTO) => {
    posthog.identify(user.id.toString(), {
      fullName: `${user.firstName} ${user.lastName}`,
      tenantName: user.tenant.tenant,
      language: user.accountLanguage || 'unknown',
      email: user.email,
    });
  };

  const capture = <T extends CustomPosthogEvent>(event: T, properties: EventProperties[T]) => {
    try {
      posthog.capture(event, properties);
    } catch (error) {
      console.error(`Failed to capture PostHog event: ${event}`, error);
    }
  };

  return {
    identifyPosthog,
    capture,
  };
}
