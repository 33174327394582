export default `<svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_597_21478)">
<circle cx="32" cy="28" r="24" fill="white"/>
</g>
<path d="M21.2549 28.055L42.8549 28.055M42.8549 28.055L34.9093 36.0006M42.8549 28.055L34.9093 20.1094" stroke="url(#paint0_linear_597_21478)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<filter id="filter0_d_597_21478" x="0" y="0" width="64" height="64" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="4"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_597_21478"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_597_21478" result="shape"/>
</filter>
<linearGradient id="paint0_linear_597_21478" x1="21.2549" y1="28.055" x2="56.7406" y2="28.055" gradientUnits="userSpaceOnUse">
<stop stop-color="#4F46E5"/>
<stop offset="0.734393" stop-color="#FFA7BB"/>
</linearGradient>
</defs>
</svg>
`;
