import { setI18nLanguage } from '../plugins/i18nPlugin';
import { useUserStore } from '../stores/userStore';
import { useUserflow } from './useUserflow';
import { AccountLanguage } from './useLanguageService';
import { Tenant } from './tenantService';

export default function useI18nUtils() {
  async function setLanguage(locale?: AccountLanguage | null, tenant?: Tenant) {
    const { loggedInInfo } = useUserStore();
    const { identify } = useUserflow();

    const languageSetted = await setI18nLanguage(locale, tenant);

    if (loggedInInfo?.user) {
      identify(languageSetted, loggedInInfo.user);
    }

    return languageSetted;
  }

  return { setLanguage };
}
