export default `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.0484 5.94693C11.4402 4.73372 11.636 4.12711 12 4.12711C12.364 4.12711 12.5598 4.73372 12.9516 5.94694L14.0263 9.27503C14.1342 9.60916 14.1882 9.77622 14.3197 9.87198C14.4513 9.96774 14.6268 9.96774 14.9779 9.96774H17.911C19.1831 9.96774 19.8192 9.96774 19.9312 10.3137C20.0432 10.6597 19.5278 11.0325 18.4971 11.778L15.964 13.6101C15.6799 13.8156 15.5378 13.9183 15.4873 14.0728C15.4368 14.2273 15.4907 14.3941 15.5985 14.7278L16.6856 18.0924C17.073 19.2915 17.2667 19.8911 16.9736 20.1043C16.6805 20.3175 16.1697 19.9485 15.1483 19.2104L12.5857 17.3587C12.3031 17.1545 12.1617 17.0524 12 17.0524C11.8383 17.0524 11.6969 17.1545 11.4143 17.3587L8.85167 19.2104C7.83025 19.9485 7.31955 20.3175 7.02641 20.1043C6.73327 19.8911 6.92699 19.2915 7.31442 18.0924L8.40151 14.7278C8.50932 14.3941 8.56322 14.2273 8.51271 14.0728C8.46219 13.9183 8.32012 13.8156 8.03599 13.6101L5.50294 11.778C4.47217 11.0325 3.95679 10.6597 4.0688 10.3137C4.18081 9.96774 4.81687 9.96774 6.08899 9.96774H9.02207C9.37319 9.96774 9.54875 9.96774 9.68029 9.87198C9.81184 9.77622 9.86579 9.60916 9.97369 9.27503L11.0484 5.94693Z" fill="url(#paint0_linear_539_10635)" stroke="url(#paint1_linear_539_10635)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_539_10635" x1="3" y1="12" x2="32.5714" y2="12" gradientUnits="userSpaceOnUse">
<stop stop-color="#4F46E5"/>
<stop offset="0.734393" stop-color="#FFA7BB"/>
</linearGradient>
<linearGradient id="paint1_linear_539_10635" x1="3" y1="12" x2="32.5714" y2="12" gradientUnits="userSpaceOnUse">
<stop stop-color="#4F46E5"/>
<stop offset="0.734393" stop-color="#FFA7BB"/>
</linearGradient>
</defs>
</svg>

`;
