export default `<svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.60323 33.2303L16.7691 44.3962C17.5683 45.1954 18.8652 45.2016 19.6718 44.4139L5.58609 30.3281C4.79864 31.1351 4.80484 32.4316 5.60376 33.2305L5.60323 33.2303Z" fill="currentColor"/>
<path d="M16.5049 30.0479L30.047 16.5059L33.4919 19.9508L19.9498 33.4929L16.5049 30.0479Z" fill="currentColor"/>
<path d="M6.47274 25.5059L6.36331 25.6154C5.32291 26.6558 5.32291 28.3488 6.36331 29.3893L6.72293 29.7489L6.71983 29.7486L20.25 43.2787V43.276L20.6096 43.6356C21.65 44.676 23.3425 44.676 24.3835 43.6353L24.493 43.5258C25.5333 42.4854 25.5333 40.7924 24.4933 39.7519L10.2472 25.5059C9.2065 24.4655 7.51403 24.4655 6.473 25.5059L6.47274 25.5059Z" fill="currentColor"/>
<path d="M25.6134 6.36478L25.5039 6.47421C24.4635 7.51461 24.4635 9.20763 25.5039 10.2481L39.7508 24.495C40.7912 25.5354 42.4836 25.5354 43.5247 24.4946L43.6341 24.3852C44.6745 23.3448 44.6745 21.6518 43.6344 20.6113L29.3876 6.36526C28.3465 5.32486 26.6542 5.32486 25.6134 6.36526L25.6134 6.36478Z" fill="currentColor"/>
<path d="M30.3232 5.58963L44.4121 19.6738C45.2011 18.8671 45.1956 17.5693 44.3957 16.7698L33.2298 5.60392C32.4294 4.80344 31.1301 4.79876 30.3237 5.58966L30.3232 5.58963Z" fill="currentColor"/>
<path d="M8.64403 41.3552C9.38219 42.0933 10.4375 42.3944 11.4667 42.1606L12.3227 41.9656L8.03387 37.6768L7.83887 38.5327C7.60419 39.562 7.90553 40.6171 8.64403 41.3552Z" fill="currentColor"/>
<path d="M38.5327 7.83871L37.6768 8.03371L41.9656 12.3225L42.1606 11.4666C42.3949 10.4373 42.0936 9.38196 41.3552 8.64387C40.617 7.90571 39.5619 7.60434 38.5327 7.83871Z" fill="currentColor"/>
</svg>

`;
