export default `<svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.2484 14.6358C18.2853 15.1926 14.2284 19.1326 13.6242 24.0577C13.1063 28.3403 15.1347 32.2375 18.3716 34.4217C19.2347 34.9784 19.7526 35.9206 19.7526 36.9484V37.8478H29.1179V36.9913C29.1179 36.0063 29.5926 35.0213 30.4558 34.4645C33.4337 32.5373 35.3758 29.1968 35.3758 25.3853C35.4189 19.0041 29.8516 13.9078 23.2484 14.6358Z" fill="currentColor"/>
<path d="M19.7958 42.6014C19.7958 42.9869 19.9684 43.3295 20.2705 43.5864L21.6084 44.6999C21.8242 44.8712 22.1263 44.9997 22.4284 44.9997H26.5716C26.8737 44.9997 27.1758 44.914 27.3916 44.6999L28.7295 43.5864C29.0316 43.3295 29.2042 42.9869 29.2042 42.6014V40.2031H19.7958V42.6014Z" fill="currentColor"/>
<path d="M24.5 11.2955C25.2337 11.2955 25.8379 10.6959 25.8379 9.96788V6.32762C25.8379 5.59957 25.2337 5 24.5 5C23.7663 5 23.1621 5.59957 23.1621 6.32762V9.92505C23.1621 10.6959 23.7231 11.2955 24.5 11.2955Z" fill="currentColor"/>
<path d="M15.5663 12.709C15.8253 13.1372 16.2568 13.3942 16.7316 13.3942C16.9474 13.3942 17.2063 13.3513 17.3789 13.2229C18.0263 12.8374 18.2421 12.0237 17.8537 11.4242L16.0411 8.29782C15.6526 7.65542 14.8326 7.44128 14.2284 7.82672C13.5811 8.21216 13.3653 9.02587 13.7537 9.62544L15.5663 12.709Z" fill="currentColor"/>
<path d="M7.23684 17.0342L10.3874 18.8329C10.6032 18.9614 10.8189 19.0043 11.0347 19.0043C11.5095 19.0043 11.9411 18.7473 12.2 18.319C12.5884 17.6766 12.3295 16.8629 11.7253 16.5203L8.57474 14.7216C7.92737 14.3362 7.10737 14.5931 6.76211 15.1927C6.37369 15.8351 6.58948 16.6488 7.23684 17.0342Z" fill="currentColor"/>
<path d="M10.3011 25.3852C10.3011 24.6572 9.69684 24.0576 8.96316 24.0576H5.33789C4.60421 24.0576 4 24.6572 4 25.3852C4 26.1133 4.60421 26.7129 5.33789 26.7129H8.96316C9.74 26.7129 10.3011 26.1133 10.3011 25.3852Z" fill="currentColor"/>
<path d="M10.3874 31.9374L7.23684 33.7361C6.58948 34.1216 6.37369 34.9353 6.76211 35.5348C7.02105 35.9631 7.45263 36.2201 7.92737 36.2201C8.14316 36.2201 8.40211 36.1772 8.57474 36.0488L11.7253 34.25C12.3726 33.8646 12.5884 33.0509 12.2 32.4513C11.8547 31.7661 11.0347 31.552 10.3874 31.9374Z" fill="currentColor"/>
<path d="M41.7632 33.6941L38.6127 31.8954C37.9653 31.51 37.1453 31.7669 36.8 32.3665C36.4116 33.0089 36.6705 33.8226 37.2748 34.1652L40.4253 35.9639C40.6411 36.0924 40.8569 36.1353 41.0727 36.1353C41.5474 36.1353 41.979 35.8783 42.2379 35.45C42.6263 34.8933 42.4105 34.0796 41.7632 33.6941Z" fill="currentColor"/>
<path d="M43.6621 24.0156H40.0369C39.3032 24.0156 38.699 24.6152 38.699 25.3432C38.699 26.0713 39.3032 26.6709 40.0369 26.6709H43.6621C44.3958 26.6709 45 26.0713 45 25.3432C45 24.6152 44.3958 24.0156 43.6621 24.0156Z" fill="currentColor"/>
<path d="M37.9221 18.9611C38.1379 18.9611 38.3969 18.9183 38.5695 18.7898L41.72 16.9911C42.3674 16.6056 42.5832 15.7919 42.1948 15.1923C41.8063 14.5499 40.9863 14.3358 40.3821 14.7213L37.2316 16.52C36.5842 16.9054 36.3684 17.7191 36.7569 18.3187C37.0158 18.747 37.4474 18.9611 37.9221 18.9611Z" fill="currentColor"/>
<path d="M31.5779 13.1798C31.7937 13.3082 32.0095 13.3511 32.2253 13.3511C32.7 13.3511 33.1316 13.0941 33.3905 12.6658L35.2032 9.5395C35.5916 8.8971 35.3326 8.0834 34.7284 7.74079C34.0811 7.35535 33.2611 7.56948 32.9158 8.21188L31.1032 11.3382C30.7148 11.9806 30.9305 12.7943 31.5779 13.1798Z" fill="currentColor"/>
</svg>

`;
