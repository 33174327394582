import * as Sentry from '@sentry/vue';
import { App } from 'vue';
import { Router } from 'vue-router';
import { envs } from '../env';

export const initSentry = (app: App<Element>, router: Router) => {
  const isStaging = envs.VITE_IS_STAGING_ENV === 'true';

  Sentry.init({
    app,
    dsn: 'https://10619912f9051951c37126ba0f27bb88@o4505947490811904.ingest.sentry.io/4505948978544640',
    tracePropagationTargets: ['localhost', envs.VITE_API_URL],
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.replayIntegration({ maskAllText: false }),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: isStaging ? 0 : 0.8, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: isStaging ? 'staging' : 'master',
  });
};
