import { AccountLanguage } from '@/services/useLanguageService';
import { useUtilsService } from '../services/utilsService';
import { PhoneCode } from '@/components/subcomponents/CountryCodeSelectorComponent.vue';

const { getOffsetFromTimeZone } = useUtilsService();

export const timeZonesName = [
  'Pacific/Pago Pago',
  'Pacific/Midway',
  'Pacific/Honolulu',
  'America/Adak',
  'America/Anchorage',
  'America/Los Angeles',
  'America/Phoenix',
  'America/Denver',
  'America/Chicago',
  'America/New York',
  'America/Halifax',
  'America/Argentina/Buenos Aires',
  'America/Sao Paulo',
  'Atlantic/Azores',
  'Europe/London',
  'Europe/Amsterdam',
  'Europe/Berlin',
  'Europe/Rome',
  'Europe/Paris',
  'Europe/Madrid',
  'Europe/Warsaw',
  'Africa/Cairo',
  'Asia/Jerusalem',
  'Asia/Amman',
  'Asia/Beirut',
  'Europe/Istanbul',
  'Africa/Nairobi',
  'Asia/Riyadh',
  'Asia/Baghdad',
  'Asia/Tehran',
  'Asia/Dubai',
  'Asia/Yerevan',
  'Asia/Baku',
  'Asia/Kabul',
  'Asia/Tashkent',
  'Asia/Kolkata',
  'Asia/Kathmandu',
  'Asia/Almaty',
  'Asia/Dhaka',
  'Asia/Yangon',
  'Asia/Krasnoyarsk',
  'Asia/Bangkok',
  'Asia/Jakarta',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Asia/Taipei',
  'Asia/Hong Kong',
  'Asia/Kuala Lumpur',
  'Asia/Tokyo',
  'Asia/Seoul',
  'Asia/Yakutsk',
  'Australia/Darwin',
  'Australia/Adelaide',
  'Australia/Sydney',
  'Australia/Brisbane',
  'Australia/Hobart',
  'Asia/Vladivostok',
  'Australia/Lord Howe',
  'Pacific/Noumea',
  'Pacific/Fiji',
  'Pacific/Auckland',
  'Pacific/Tongatapu',
];

const computedTimeZones = timeZonesName
  .filter(n => Intl.supportedValuesOf('timeZone').includes(n))
  .map(name => {
    const minOffset = getOffsetFromTimeZone(name);
    const hours = Math.abs(Math.floor(minOffset / 60));
    const minutes = minOffset % 60;
    const sign = minOffset >= 0 ? '+' : '-';
    const hourLabel = hours < 10 ? `0${hours}` : hours;
    const minuteLabel = minutes < 10 ? `0${minutes}` : minutes;
    const label = `${name} (GMT${sign}${hourLabel}:${minuteLabel})`;
    return { name, label, value: minOffset };
  });
computedTimeZones.sort((a, b) => a.value - b.value);

export const timeZones = computedTimeZones;

export const times = [
  { label: '7:00 am', value: '07-00' },
  { label: '7:30 am', value: '07-30' },
  { label: '8:00 am', value: '08-00' },
  { label: '8:30 am', value: '08-30' },
  { label: '9:00 am', value: '09-00' },
  { label: '9:30 am', value: '09-30' },
  { label: '10:00 am', value: '10-00' },
  { label: '10:30 am', value: '10-30' },
  { label: '11:00 am', value: '11-00' },
  { label: '11:30 am', value: '11-30' },
  { label: '12:00 pm', value: '12-00' },
  { label: '12:30 pm', value: '12-30' },
  { label: '1:00 pm', value: '13-00' },
  { label: '1:30 pm', value: '13-30' },
  { label: '2:00 pm', value: '14-00' },
  { label: '2:30 pm', value: '14-30' },
  { label: '3:00 pm', value: '15-00' },
  { label: '3:30 pm', value: '15-30' },
  { label: '4:00 pm', value: '16-00' },
  { label: '4:30 pm', value: '16-30' },
  { label: '5:00 pm', value: '17-00' },
  { label: '5:30 pm', value: '17-30' },
  { label: '6:00 pm', value: '18-00' },
  { label: '6:30 pm', value: '18-30' },
  { label: '7:00 pm', value: '19-00' },
  { label: '7:30 pm', value: '19-30' },
  { label: '8:00 pm', value: '20-00' },
  { label: '8:30 pm', value: '20-30' },
  { label: '9:00 pm', value: '21-00' },
  { label: '9:30 pm', value: '21-30' },
  { label: '10:00 pm', value: '22-00' },
  { label: '10:30 pm', value: '22-30' },
  { label: '11:00 pm', value: '23-00' },
  { label: '11:30 pm', value: '23-30' },
  { label: '12:00 am', value: '00-00' },
  { label: '12:30 am', value: '00-30' },
  { label: '1:00 am', value: '01-00' },
  { label: '1:30 am', value: '01-30' },
  { label: '2:00 am', value: '02-00' },
  { label: '2:30 am', value: '02-30' },
  { label: '3:00 am', value: '03-00' },
  { label: '3:30 am', value: '03-30' },
  { label: '4:00 am', value: '04-00' },
  { label: '4:30 am', value: '04-30' },
  { label: '5:00 am', value: '05-00' },
  { label: '5:30 am', value: '05-30' },
  { label: '6:00 am', value: '06-00' },
  { label: '6:30 am', value: '06-30' },
];

export const countries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo',
  'Congo, the Democratic Republic of',
  'Cook Islands',
  'Costa Rica',
  "Cote d'Ivoire",
  'Croatia (Hrvatska)',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands (Malvinas)',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'France Metropolitan',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard and Mc Donald Islands',
  'Holy See (Vatican City State)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea, Democratic People's Republic of",
  'Korea, Republic of',
  'Kuwait',
  'Kyrgyzstan',
  "Lao, People's Democratic Republic",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libyan Arab Jamahiriya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia, The Former Yugoslav Republic of',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia, Federated States of',
  'Moldova, Republic of',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia (Slovak Republic)',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'Spain',
  'Sri Lanka',
  'Saint Helena',
  'Saint Pierre and Miquelon',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen Islands',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna Islands',
  'Western Sahara',
  'Yemen',
  'Yugoslavia',
  'Zambia',
  'Zimbabwe',
  'Palestine',
];

export const countryCodeArray = [
  'AF',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'BN',
  'BG',
  'BF',
  'BI',
  'KH',
  'CM',
  'CA',
  'CV',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CG',
  'CD',
  'CK',
  'CR',
  'CI',
  'HR',
  'CU',
  'CY',
  'CZ',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GW',
  'GY',
  'HT',
  'VA',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IM',
  'IL',
  'IT',
  'JM',
  'JP',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KP',
  'KR',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MK',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'ME',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'AN',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'RE',
  'RO',
  'RU',
  'RW',
  'SH',
  'KN',
  'LC',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SZ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'GB',
  'US',
  'UM',
  'UY',
  'UZ',
  'VU',
  'VE',
  'VN',
  'VG',
  'VI',
  'WF',
  'EH',
  'YE',
  'ZM',
  'ZW',
  'IC',
  'JS',
  'XK',
  'NK',
  'BL',
  'MF',
  'SX',
  'SS',
] as const;
export type CountryCode = (typeof countryCodeArray)[number];

export const countriesWithCodes: Record<CountryCode, string> = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo, The Democratic Republic of the',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: 'Cote dIvoire',
  HR: 'Croatia',
  CU: 'Cuba',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  VA: 'Holy See (Vatican City State)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea, Democratic People's Republic of",
  KR: 'Korea, Republic of',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao, People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libyan Arab Jamahiriya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macau',
  MK: 'Macedonia, The Former Yugoslav Republic of',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia, Federated States of',
  MD: 'Moldova, Republic of',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  AN: 'Netherlands Antilles',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RU: 'Russia',
  RW: 'Rwanda',
  SH: 'Saint Helena',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'East Timor',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UM: 'United States Minor Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Vietnam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  WF: 'Wallis and Futuna Islands',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  IC: 'Canary Islands',
  JS: 'Jersey',
  XK: 'Kosovo',
  NK: 'North Korea',
  BL: 'Saint Barthélemy',
  MF: 'Saint Martin',
  SX: 'Sint Maarten',
  SS: 'South Sudan',
};

export const phoneCodes: PhoneCode[] = [
  { label: 'Afghanistan', value: '+93', abbreviation: 'AF' },
  { label: 'Albania', value: '+355', abbreviation: 'AL' },
  { label: 'Algeria', value: '+213', abbreviation: 'DZ' },
  { label: 'American Samoa', value: '+684', abbreviation: 'AS' },
  { label: 'Andorra', value: '+376', abbreviation: 'AD' },
  { label: 'Angola', value: '+244', abbreviation: 'AO' },
  { label: 'Anguilla', value: '+264', abbreviation: 'AI' },
  { label: 'Antarctica', value: '+672', abbreviation: 'AQ' },
  { label: 'Antigua and Barbuda', value: '+268', abbreviation: 'AG' },
  { label: 'Argentina', value: '+54', abbreviation: 'AR' },
  { label: 'Armenia', value: '+374', abbreviation: 'AM' },
  { label: 'Aruba', value: '+297', abbreviation: 'AW' },
  { label: 'Australia', value: '+61', abbreviation: 'AU' },
  { label: 'Austria', value: '+43', abbreviation: 'AT' },
  { label: 'Azerbaijan', value: '+994', abbreviation: 'AZ' },
  { label: 'Bahamas', value: '+242', abbreviation: 'BS' },
  { label: 'Bahrain', value: '+973', abbreviation: 'BH' },
  { label: 'Bangladesh', value: '+880', abbreviation: 'BD' },
  { label: 'Barbados', value: '+246', abbreviation: 'BB' },
  { label: 'Belarus', value: '+375', abbreviation: 'BY' },
  { label: 'Belgium', value: '+32', abbreviation: 'BE' },
  { label: 'Belize', value: '+501', abbreviation: 'BZ' },
  { label: 'Benin', value: '+229', abbreviation: 'BJ' },
  { label: 'Bermuda', value: '+441', abbreviation: 'BM' },
  { label: 'Bhutan', value: '+975', abbreviation: 'BT' },
  { label: 'Bolivia', value: '+591', abbreviation: 'BO' },
  { label: 'Bosnia and Herzegovina', value: '+387', abbreviation: 'BA' },
  { label: 'Botswana', value: '+267', abbreviation: 'BW' },
  { label: 'Bouvet Island', value: '+47', abbreviation: 'BV' },
  { label: 'Brazil', value: '+55', abbreviation: 'BR' },
  { label: 'British Indian Ocean Territory', value: '+246', abbreviation: 'IO' },
  { label: 'British Virgin Islands', value: '+284', abbreviation: 'VG' },
  { label: 'Brunei Darussalam', value: '+673', abbreviation: 'BN' },
  { label: 'Bulgaria', value: '+359', abbreviation: 'BG' },
  { label: 'Burkina Faso', value: '+226', abbreviation: 'BF' },
  { label: 'Burundi', value: '+257', abbreviation: 'BI' },
  { label: 'Cambodia', value: '+855', abbreviation: 'KH' },
  { label: 'Cameroon', value: '+237', abbreviation: 'CM' },
  { label: 'Canada', value: '+1', abbreviation: 'CA' },
  { label: 'Cape Verde', value: '+238', abbreviation: 'CV' },
  { label: 'Cayman Islands', value: '+345', abbreviation: 'KY' },
  { label: 'Central African Republic', value: '+236', abbreviation: 'CF' },
  { label: 'Chad', value: '+235', abbreviation: 'TG' },
  { label: 'Chile', value: '+56', abbreviation: 'CL' },
  { label: 'China', value: '+86', abbreviation: 'CN' },
  { label: 'Christmas Island', value: '+61', abbreviation: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: '+61', abbreviation: 'CC' },
  { label: 'Colombia', value: '+57', abbreviation: 'CO' },
  { label: 'Comoros', value: '+269', abbreviation: 'KM' },
  { label: 'Cook Islands', value: '+682', abbreviation: 'CK' },
  { label: 'Costa Rica', value: '+506', abbreviation: 'CR' },
  { label: 'Croatia', value: '+385', abbreviation: 'HR' },
  { label: 'Cuba', value: '+53', abbreviation: 'CU' },
  { label: 'Cyprus', value: '+357', abbreviation: 'CY' },
  { label: 'Czech Republic', value: '+420', abbreviation: 'CZ' },
  { label: 'Congo, Democratic Republic', value: '+243', abbreviation: 'CD' },
  { label: 'Denmark', value: '+45', abbreviation: 'DK' },
  { label: 'Djibouti', value: '+253', abbreviation: 'DJ' },
  { label: 'Dominica', value: '+767', abbreviation: 'DM' },
  { label: 'Dominican Republic', value: '+809', abbreviation: 'DO' },
  { label: 'East Timor', value: '+670', abbreviation: 'TL' },
  { label: 'Ecuador', value: '+593', abbreviation: 'EC' },
  { label: 'Egypt', value: '+20', abbreviation: 'EG' },
  { label: 'El Salvador', value: '+503', abbreviation: 'SV' },
  { label: 'Equatorial Guinea', value: '+240', abbreviation: 'GQ' },
  { label: 'Eritrea', value: '+291', abbreviation: 'ER' },
  { label: 'Estonia', value: '+372', abbreviation: 'EE' },
  { label: 'Ethiopia', value: '+251', abbreviation: 'ET' },
  { label: 'Falkland Islands', value: '+500', abbreviation: 'FK' },
  { label: 'Faroe Islands', value: '+298', abbreviation: 'FO' },
  { label: 'Fiji', value: '+679', abbreviation: 'FJ' },
  { label: 'Finland', value: '+358', abbreviation: 'FI' },
  { label: 'France', value: '+33', abbreviation: 'FR' },
  { label: 'French Polynesia', value: '+689', abbreviation: 'PF' },
  { label: 'Gabon', value: '+241', abbreviation: 'GA' },
  { label: 'Gambia', value: '+220', abbreviation: 'GM' },
  { label: 'Georgia', value: '+995', abbreviation: 'GE' },
  { label: 'Germany', value: '+49', abbreviation: 'DE' },
  { label: 'Ghana', value: '+233', abbreviation: 'GH' },
  { label: 'Gibraltar', value: '+350', abbreviation: 'GI' },
  { label: 'Greece', value: '+30', abbreviation: 'GR' },
  { label: 'Greenland', value: '+299', abbreviation: 'GL' },
  { label: 'Grenada', value: '+473', abbreviation: 'GD' },
  { label: 'Guam', value: '+671', abbreviation: 'GU' },
  { label: 'Guatemala', value: '+502', abbreviation: 'GT' },
  { label: 'Guernsey', value: '+1481', abbreviation: 'GG' },
  { label: 'Guinea', value: '+224', abbreviation: 'GN' },
  { label: 'Guinea-Bissau', value: '+245', abbreviation: 'GW' },
  { label: 'Guyana', value: '+592', abbreviation: 'GY' },
  { label: 'Haiti', value: '+509', abbreviation: 'HT' },
  { label: 'Honduras', value: '+504', abbreviation: 'HN' },
  { label: 'Hong Kong', value: '+852', abbreviation: 'HK' },
  { label: 'Hungary', value: '+36', abbreviation: 'HU' },
  { label: 'Iceland', value: '+354', abbreviation: 'IS' },
  { label: 'India', value: '+91', abbreviation: 'IN' },
  { label: 'Indonesia', value: '+62', abbreviation: 'ID' },
  { label: 'Iran', value: '+98', abbreviation: 'IR' },
  { label: 'Iraq', value: '+964', abbreviation: 'IQ' },
  { label: 'Ireland', value: '+353', abbreviation: 'IE' },
  { label: 'Isle of Man', value: '+1624', abbreviation: 'IM' },
  { label: 'Israel', value: '+972', abbreviation: 'IL' },
  { label: 'Italy', value: '+39', abbreviation: 'IT' },
  { label: 'Ivory Coast', value: '+225', abbreviation: 'IC' },
  { label: 'Jamaica', value: '+876', abbreviation: 'JM' },
  { label: 'Japan', value: '+81', abbreviation: 'JP' },
  { label: 'Jersey', value: '+1534', abbreviation: 'JS' },
  { label: 'Jordan', value: '+962', abbreviation: 'JO' },
  { label: 'Kazakhstan', value: '+7', abbreviation: 'KZ' },
  { label: 'Kenya', value: '+254', abbreviation: 'KE' },
  { label: 'Kiribati', value: '+686', abbreviation: 'KI' },
  { label: 'Kosovo', value: '+383', abbreviation: 'XK' },
  { label: 'Kuwait', value: '+965', abbreviation: 'KW' },
  { label: 'Kyrgyzstan', value: '+996', abbreviation: 'KG' },
  { label: 'Laos', value: '+856', abbreviation: 'LA' },
  { label: 'Latvia', value: '+371', abbreviation: 'LV' },
  { label: 'Lebanon', value: '+961', abbreviation: 'LB' },
  { label: 'Lesotho', value: '+266', abbreviation: 'LS' },
  { label: 'Liberia', value: '+231', abbreviation: 'LR' },
  { label: 'Libya', value: '+218', abbreviation: 'LY' },
  { label: 'Liechtenstein', value: '+423', abbreviation: 'LI' },
  { label: 'Lithuania', value: '+370', abbreviation: 'LT' },
  { label: 'Luxembourg', value: '+352', abbreviation: 'LU' },
  { label: 'Macau', value: '+853', abbreviation: 'MO' },
  { label: 'Macedonia', value: '+389', abbreviation: 'MK' },
  { label: 'Madagascar', value: '+261', abbreviation: 'MG' },
  { label: 'Malawi', value: '+265', abbreviation: 'MW' },
  { label: 'Malaysia', value: '+60', abbreviation: 'MY' },
  { label: 'Maldives', value: '+960', abbreviation: 'MV' },
  { label: 'Mali', value: '+223', abbreviation: 'ML' },
  { label: 'Malta', value: '+356', abbreviation: 'MT' },
  { label: 'Marshall Islands', value: '+692', abbreviation: 'MH' },
  { label: 'Mauritania', value: '+222', abbreviation: 'MQ' },
  { label: 'Mauritius', value: '+230', abbreviation: 'MU' },
  { label: 'Mayotte', value: '+262', abbreviation: 'YT' },
  { label: 'Mexico', value: '+52', abbreviation: 'MX' },
  { label: 'Micronesia', value: '+691', abbreviation: 'FM' },
  { label: 'Moldova', value: '+373', abbreviation: 'MD' },
  { label: 'Monaco', value: '+377', abbreviation: 'MC' },
  { label: 'Mongolia', value: '+976', abbreviation: 'MN' },
  { label: 'Montenegro', value: '+382', abbreviation: 'ME' },
  { label: 'Montserrat', value: '+664', abbreviation: 'MS' },
  { label: 'Morocco', value: '+212', abbreviation: 'MA' },
  { label: 'Mozambique', value: '+258', abbreviation: 'MZ' },
  { label: 'Myanmar', value: '+95', abbreviation: 'MM' },
  { label: 'Namibia', value: '+264', abbreviation: 'NA' },
  { label: 'Nauru', value: '+674', abbreviation: 'NR' },
  { label: 'Nepal', value: '+977', abbreviation: 'NP' },
  { label: 'Netherlands', value: '+31', abbreviation: 'NL' },
  { label: 'Netherlands Antilles', value: '+599', abbreviation: 'AN' },
  { label: 'New Caledonia', value: '+687', abbreviation: 'NC' },
  { label: 'New Zealand', value: '+64', abbreviation: 'NZ' },
  { label: 'Nicaragua', value: '+505', abbreviation: 'NI' },
  { label: 'Niger', value: '+227', abbreviation: 'NE' },
  { label: 'Nigeria', value: '+234', abbreviation: 'NG' },
  { label: 'Niue', value: '+683', abbreviation: 'NU' },
  { label: 'North Korea', value: '+850', abbreviation: 'NK' },
  { label: 'Northern Mariana Islands', value: '+670', abbreviation: 'MP' },
  { label: 'Norway', value: '+47', abbreviation: 'NO' },
  { label: 'Oman', value: '+968', abbreviation: 'OM' },
  { label: 'Pakistan', value: '+92', abbreviation: 'PK' },
  { label: 'Palau', value: '+680', abbreviation: 'PW' },
  { label: 'Palestine', value: '+970', abbreviation: 'PS' },
  { label: 'Panama', value: '+507', abbreviation: 'PA' },
  { label: 'Papua New Guinea', value: '+675', abbreviation: 'PG' },
  { label: 'Paraguay', value: '+595', abbreviation: 'PY' },
  { label: 'Peru', value: '+51', abbreviation: 'PE' },
  { label: 'Philippines', value: '+63', abbreviation: 'PH' },
  { label: 'Pitcairn', value: '+64', abbreviation: 'PN' },
  { label: 'Poland', value: '+48', abbreviation: 'PL' },
  { label: 'Portugal', value: '+351', abbreviation: 'PT' },
  { label: 'Puerto Rico', value: '+787', abbreviation: 'PR' },
  { label: 'Qatar', value: '+974', abbreviation: 'QA' },
  { label: 'Republic of the Congo', value: '+242', abbreviation: 'CD' },
  { label: 'Reunion', value: '+262', abbreviation: 'RE' },
  { label: 'Romania', value: '+40', abbreviation: 'RO' },
  { label: 'Russia', value: '+7', abbreviation: 'RU' },
  { label: 'Rwanda', value: '+250', abbreviation: 'RW' },
  { label: 'Saint Barthelemy', value: '+590', abbreviation: 'BL' },
  { label: 'Saint Helena', value: '+290', abbreviation: 'SH' },
  { label: 'Saint Kitts and Nevis', value: '+869', abbreviation: 'KN' },
  { label: 'Saint Lucia', value: '+758', abbreviation: 'LC' },
  { label: 'Saint Martin', value: '+590', abbreviation: 'MF' },
  { label: 'Saint Pierre and Miquelon', value: '+508', abbreviation: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: '+784', abbreviation: 'VC' },
  { label: 'Samoa', value: '+685', abbreviation: 'WS' },
  { label: 'San Marino', value: '+378', abbreviation: 'SM' },
  { label: 'Sao Tome and Principe', value: '+239', abbreviation: 'ST' },
  { label: 'Saudi Arabia', value: '+966', abbreviation: 'SA' },
  { label: 'Senegal', value: '+221', abbreviation: 'SN' },
  { label: 'Serbia', value: '+381', abbreviation: 'RS' },
  { label: 'Seychelles', value: '+248', abbreviation: 'SC' },
  { label: 'Sierra Leone', value: '+232', abbreviation: 'SL' },
  { label: 'Singapore', value: '+65', abbreviation: 'SG' },
  { label: 'Sint Maarten', value: '+721', abbreviation: 'SX' },
  { label: 'Slovakia', value: '+421', abbreviation: 'SK' },
  { label: 'Slovenia', value: '+386', abbreviation: 'SI' },
  { label: 'Solomon Islands', value: '+677', abbreviation: 'SB' },
  { label: 'Somalia', value: '+252', abbreviation: 'SO' },
  { label: 'South Africa', value: '+27', abbreviation: 'ZA' },
  { label: 'South Korea', value: '+82', abbreviation: 'KR' },
  { label: 'South Sudan', value: '+211', abbreviation: 'SS' },
  { label: 'Spain', value: '+34', abbreviation: 'ES' },
  { label: 'Sri Lanka', value: '+94', abbreviation: 'LK' },
  { label: 'Sudan', value: '+249', abbreviation: 'SD' },
  { label: 'Suriname', value: '+597', abbreviation: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: '+47', abbreviation: 'SJ' },
  { label: 'Swaziland', value: '+268', abbreviation: 'SZ' },
  { label: 'Sweden', value: '+46', abbreviation: 'SE' },
  { label: 'Switzerland', value: '+41', abbreviation: 'CH' },
  { label: 'Syria', value: '+963', abbreviation: 'SY' },
  { label: 'Taiwan', value: '+886', abbreviation: 'TW' },
  { label: 'Tajikistan', value: '+992', abbreviation: 'TJ' },
  { label: 'Tanzania', value: '+255', abbreviation: 'TZ' },
  { label: 'Thailand', value: '+66', abbreviation: 'TH' },
  { label: 'Togo', value: '+228', abbreviation: 'TG' },
  { label: 'Tokelau', value: '+690', abbreviation: 'TK' },
  { label: 'Tonga', value: '+676', abbreviation: 'TO' },
  { label: 'Trinidad and Tobago', value: '+868', abbreviation: 'TT' },
  { label: 'Tunisia', value: '+216', abbreviation: 'TN' },
  { label: 'Turkey', value: '+90', abbreviation: 'TR' },
  { label: 'Turkmenistan', value: '+993', abbreviation: 'TM' },
  { label: 'Turks and Caicos Islands', value: '+649', abbreviation: 'TC' },
  { label: 'Tuvalu', value: '+688', abbreviation: 'TV' },
  { label: 'U.S. Virgin Islands', value: '+340', abbreviation: 'VI' },
  { label: 'Uganda', value: '+256', abbreviation: 'UG' },
  { label: 'Ukraine', value: '+380', abbreviation: 'UA' },
  { label: 'United Arab Emirates', value: '+971', abbreviation: 'AE' },
  { label: 'United Kingdom', value: '+44', abbreviation: 'GB' },
  { label: 'United States', value: '+1', abbreviation: 'US' },
  { label: 'Uruguay', value: '+598', abbreviation: 'UY' },
  { label: 'Uzbekistan', value: '+998', abbreviation: 'UZ' },
  { label: 'Vanuatu', value: '+678', abbreviation: 'VU' },
  { label: 'Vatican', value: '+379', abbreviation: 'VA' },
  { label: 'Venezuela', value: '+58', abbreviation: 'VE' },
  { label: 'Vietnam', value: '+84', abbreviation: 'VN' },
  { label: 'Wallis and Futuna', value: '+681', abbreviation: 'WF' },
  { label: 'Western Sahara', value: '+212', abbreviation: 'EH' },
  { label: 'Yemen', value: '+967', abbreviation: 'YE' },
  { label: 'Zambia', value: '+260', abbreviation: 'ZM' },
  { label: 'Zimbabwe', value: '+263', abbreviation: 'ZW' },
];

export const countriesToCode: Record<string, CountryCode> = {
  afghanistan: 'AF',
  afganistán: 'AF',
  afeganistão: 'AF',
  albania: 'AL',
  albanien: 'AL',
  albanie: 'AL',
  albanië: 'AL',
  albânia: 'AL',
  algeria: 'DZ',
  algerien: 'DZ',
  argelia: 'DZ',
  algeriet: 'DZ',
  algérie: 'DZ',
  algerije: 'DZ',
  argélia: 'DZ',
  'american samoa': 'AS',
  'amerikanisch-samoa': 'AS',
  'samoa americana': 'AS',
  'samoa americane': 'AS',
  'amerikanska samoa': 'AS',
  'samoa américaines': 'AS',
  'amerikaans-samoa': 'AS',
  andorra: 'AD',
  andorre: 'AD',
  angola: 'AO',
  anguilla: 'AI',
  anguila: 'AI',
  antarctica: 'AQ',
  antarktika: 'AQ',
  antártida: 'AQ',
  antartide: 'AQ',
  antarktis: 'AQ',
  antarctique: 'AQ',
  'antigua and barbuda': 'AG',
  'antigua und barbuda': 'AG',
  'antigua y barbuda': 'AG',
  'antigua e barbuda': 'AG',
  'antigua och barbuda': 'AG',
  'antigua-et-barbuda': 'AG',
  'antigua en barbuda': 'AG',
  'antígua e barbuda': 'AG',
  argentina: 'AR',
  argentinien: 'AR',
  argentine: 'AR',
  argentinië: 'AR',
  armenia: 'AM',
  armenien: 'AM',
  arménie: 'AM',
  armenië: 'AM',
  armênia: 'AM',
  aruba: 'AW',
  australia: 'AU',
  australien: 'AU',
  australie: 'AU',
  australië: 'AU',
  austrália: 'AU',
  austria: 'AT',
  österreich: 'AT',
  österrike: 'AT',
  autriche: 'AT',
  oostenrijk: 'AT',
  áustria: 'AT',
  azerbaijan: 'AZ',
  aserbaidschan: 'AZ',
  azerbaiyán: 'AZ',
  azerbaigian: 'AZ',
  azerbajdzjan: 'AZ',
  azerbaïdjan: 'AZ',
  azerbeidzjan: 'AZ',
  azerbaijão: 'AZ',
  bahamas: 'BS',
  "bahama's": 'BS',
  bahrain: 'BH',
  bahréin: 'BH',
  bahrein: 'BH',
  bahreïn: 'BH',
  bangladesh: 'BD',
  bangladesch: 'BD',
  barbados: 'BB',
  barbade: 'BB',
  belarus: 'BY',
  bielorrusia: 'BY',
  bielorussia: 'BY',
  vitryssland: 'BY',
  biélorussie: 'BY',
  'wit-rusland': 'BY',
  bielorrússia: 'BY',
  belgium: 'BE',
  belgien: 'BE',
  bélgica: 'BE',
  belgio: 'BE',
  belgique: 'BE',
  belgië: 'BE',
  belize: 'BZ',
  belice: 'BZ',
  benin: 'BJ',
  benín: 'BJ',
  bénin: 'BJ',
  benim: 'BJ',
  bermuda: 'BM',
  bermudas: 'BM',
  bermudes: 'BM',
  bhutan: 'BT',
  bután: 'BT',
  bhoutan: 'BT',
  butão: 'BT',
  bolivia: 'BO',
  bolivien: 'BO',
  bolivie: 'BO',
  bolívia: 'BO',
  'bosnia and herzegovina': 'BA',
  'bosnien und herzegowina': 'BA',
  'bosnia y herzegovina': 'BA',
  'bosnia ed erzegovina': 'BA',
  'bosnien och hercegovina': 'BA',
  'bosnie-herzégovine': 'BA',
  'bosnië en herzegovina': 'BA',
  'bósnia e herzegovina': 'BA',
  botswana: 'BW',
  botsuana: 'BW',
  'bouvet island': 'BV',
  bouvetinsel: 'BV',
  'isla bouvet': 'BV',
  'isola bouvet': 'BV',
  bouvetön: 'BV',
  'île bouvet': 'BV',
  'bouvet-eiland': 'BV',
  'ilha bouvet': 'BV',
  brazil: 'BR',
  brasilien: 'BR',
  brasil: 'BR',
  brasile: 'BR',
  brésil: 'BR',
  brazilië: 'BR',
  'british indian ocean territory': 'IO',
  'britisches territorium im indischen ozean': 'IO',
  'territorio británico del océano índico': 'IO',
  "territorio dell'oceano indiano britannico": 'IO',
  'brittiska territoriet i indiska oceanen': 'IO',
  "territoire britannique de l'océan indien": 'IO',
  'brits indisch oceaanterritorium': 'IO',
  'território britânico do oceano índico': 'IO',
  'brunei darussalam': 'BN',
  brunei: 'BN',
  bulgaria: 'BG',
  bulgarien: 'BG',
  bulgarie: 'BG',
  bulgarije: 'BG',
  bulgária: 'BG',
  'burkina faso': 'BF',
  burundi: 'BI',
  cambodia: 'KH',
  kambodscha: 'KH',
  camboya: 'KH',
  cambogia: 'KH',
  kambodja: 'KH',
  cambodge: 'KH',
  cambodja: 'KH',
  camboja: 'KH',
  cameroon: 'CM',
  kamerun: 'CM',
  camerún: 'CM',
  camerun: 'CM',
  cameroun: 'CM',
  kameroen: 'CM',
  camarões: 'CM',
  canada: 'CA',
  kanada: 'CA',
  canadá: 'CA',
  'cape verde': 'CV',
  'kap verde': 'CV',
  'cabo verde': 'CV',
  'capo verde': 'CV',
  'cap-vert': 'CV',
  kaapverdië: 'CV',
  'cayman islands': 'KY',
  caymaninseln: 'KY',
  'islas caimán': 'KY',
  'isole cayman': 'KY',
  caymanöarna: 'KY',
  'îles caïmans': 'KY',
  kaaimaneilanden: 'KY',
  'ilhas cayman': 'KY',
  'central african republic': 'CF',
  'zentralafrikanische republik': 'CF',
  'república centroafricana': 'CF',
  'repubblica centrafricana': 'CF',
  'centralafrikanska republiken': 'CF',
  'république centrafricaine': 'CF',
  'centraal-afrikaanse republiek': 'CF',
  'república centro-africana': 'CF',
  chad: 'TD',
  tschad: 'TD',
  ciad: 'TD',
  tchad: 'TD',
  tsjaad: 'TD',
  chade: 'TD',
  chile: 'CL',
  cile: 'CL',
  chili: 'CL',
  china: 'CN',
  cina: 'CN',
  kina: 'CN',
  chine: 'CN',
  'christmas island': 'CX',
  weihnachtsinsel: 'CX',
  'isla de navidad': 'CX',
  'isola di natale': 'CX',
  christmasön: 'CX',
  'île christmas': 'CX',
  christmaseiland: 'CX',
  'ilha christmas': 'CX',
  'cocos (keeling) islands': 'CC',
  'kokos (keeling) inseln': 'CC',
  'islas cocos (keeling)': 'CC',
  'isole cocos (keeling)': 'CC',
  'kokosöarna (keelingöarna)': 'CC',
  'îles cocos (keeling)': 'CC',
  'cocoseilanden (keelingeilanden)': 'CC',
  'ilhas cocos (keeling)': 'CC',
  colombia: 'CO',
  kolumbien: 'CO',
  colombie: 'CO',
  colômbia: 'CO',
  comoros: 'KM',
  komoren: 'KM',
  comoras: 'KM',
  comore: 'KM',
  komorerna: 'KM',
  comores: 'KM',
  comoren: 'KM',
  congo: 'CG',
  kongo: 'CG',
  'congo, the democratic republic of the': 'CD',
  'demokratische republik kongo': 'CD',
  'república democrática del congo': 'CD',
  'repubblica democratica del congo': 'CD',
  'demokratiska republiken kongo': 'CD',
  'république démocratique du congo': 'CD',
  'democratische republiek congo': 'CD',
  'república democrática do congo': 'CD',
  'cook islands': 'CK',
  cookinseln: 'CK',
  'islas cook': 'CK',
  'isole cook': 'CK',
  cooköarna: 'CK',
  'îles cook': 'CK',
  cookeilanden: 'CK',
  'ilhas cook': 'CK',
  'costa rica': 'CR',
  'cote divoire': 'CI',
  elfenbeinküste: 'CI',
  'costa de marfil': 'CI',
  "costa d'avorio": 'CI',
  elfenbenskusten: 'CI',
  "côte d'ivoire": 'CI',
  ivoorkust: 'CI',
  'costa do marfim': 'CI',
  croatia: 'HR',
  kroatien: 'HR',
  croacia: 'HR',
  croazia: 'HR',
  croatie: 'HR',
  kroatië: 'HR',
  croácia: 'HR',
  cuba: 'CU',
  kuba: 'CU',
  cyprus: 'CY',
  zypern: 'CY',
  chipre: 'CY',
  cipro: 'CY',
  cypern: 'CY',
  chypre: 'CY',
  'czech republic': 'CZ',
  'tschechische republik': 'CZ',
  'república checa': 'CZ',
  'repubblica ceca': 'CZ',
  tjeckien: 'CZ',
  'république tchèque': 'CZ',
  tsjechië: 'CZ',
  'república tcheca': 'CZ',
  denmark: 'DK',
  dänemark: 'DK',
  dinamarca: 'DK',
  danimarca: 'DK',
  danmark: 'DK',
  danemark: 'DK',
  denemarken: 'DK',
  djibouti: 'DJ',
  dschibuti: 'DJ',
  yibuti: 'DJ',
  gibuti: 'DJ',
  djibuti: 'DJ',
  dominica: 'DM',
  dominique: 'DM',
  'dominican republic': 'DO',
  'dominikanische republik': 'DO',
  'república dominicana': 'DO',
  'repubblica dominicana': 'DO',
  'dominikanska republiken': 'DO',
  'république dominicaine': 'DO',
  'dominicaanse republiek': 'DO',
  ecuador: 'EC',
  équateur: 'EC',
  equador: 'EC',
  egypt: 'EG',
  ägypten: 'EG',
  egipto: 'EG',
  egitto: 'EG',
  egypten: 'EG',
  égypte: 'EG',
  egypte: 'EG',
  egito: 'EG',
  'el salvador': 'SV',
  salvador: 'SV',
  'equatorial guinea': 'GQ',
  äquatorialguinea: 'GQ',
  'guinea ecuatorial': 'GQ',
  'guinea equatoriale': 'GQ',
  ekvatorialguinea: 'GQ',
  'guinée équatoriale': 'GQ',
  'equatoriaal-guinea': 'GQ',
  'guiné equatorial': 'GQ',
  eritrea: 'ER',
  érythrée: 'ER',
  eritreia: 'ER',
  estonia: 'EE',
  estland: 'EE',
  estonie: 'EE',
  estônia: 'EE',
  ethiopia: 'ET',
  äthiopien: 'ET',
  etiopía: 'ET',
  etiopia: 'ET',
  etiopien: 'ET',
  éthiopie: 'ET',
  ethiopië: 'ET',
  etiópia: 'ET',
  'falkland islands (malvinas)': 'FK',
  'falklandinseln (malvinas)': 'FK',
  'islas malvinas (falkland)': 'FK',
  'isole falkland (malvine)': 'FK',
  'falkland öarna (falklandsöarna)': 'FK',
  'îles falkland (malvinas)': 'FK',
  'falklandeilanden (malvinas)': 'FK',
  'ilhas malvinas (falkland)': 'FK',
  'faroe islands': 'FO',
  färöer: 'FO',
  'islas feroe': 'FO',
  'isole fær øer': 'FO',
  färöarna: 'FO',
  'îles féroé': 'FO',
  faeröer: 'FO',
  'ilhas faroé': 'FO',
  fiji: 'FJ',
  fidschi: 'FJ',
  figi: 'FJ',
  fidji: 'FJ',
  finland: 'FI',
  finnland: 'FI',
  finlandia: 'FI',
  finlande: 'FI',
  finlândia: 'FI',
  france: 'FR',
  frankreich: 'FR',
  francia: 'FR',
  frankrike: 'FR',
  frankrijk: 'FR',
  frança: 'FR',
  'french guiana': 'GF',
  'französisch-guayana': 'GF',
  'guyana francesa': 'GF',
  'guyana francese': 'GF',
  'franska guyana': 'GF',
  'guyane française': 'GF',
  'frans-guyana': 'GF',
  'guiana francesa': 'GF',
  'french polynesia': 'PF',
  'französisch-polynesien': 'PF',
  'polinesia francesa': 'PF',
  'polinesia francese': 'PF',
  'franska polynesien': 'PF',
  'polynésie française': 'PF',
  'frans-polynesië': 'PF',
  'polinésia francesa': 'PF',
  'french southern territories': 'TF',
  'französische südliche und antarktische gebiete': 'TF',
  'territorios australes y antárticas franceses': 'TF',
  'territori francesi meridionali e antarctici': 'TF',
  'franska sydterritorierna': 'TF',
  'terres australes et antarctiques françaises': 'TF',
  'franse zuidelijke en antarctische territoria': 'TF',
  'territórios austrais e antárcticos franceses': 'TF',
  gabon: 'GA',
  gabun: 'GA',
  gabón: 'GA',
  gabão: 'GA',
  gambia: 'GM',
  gambie: 'GM',
  gâmbia: 'GM',
  georgia: 'GE',
  georgien: 'GE',
  géorgie: 'GE',
  georgië: 'GE',
  geórgia: 'GE',
  germany: 'DE',
  deutschland: 'DE',
  alemania: 'DE',
  germania: 'DE',
  tyskland: 'DE',
  allemagne: 'DE',
  duitsland: 'DE',
  alemanha: 'DE',
  ghana: 'GH',
  gana: 'GH',
  gibraltar: 'GI',
  gibilterra: 'GI',
  greece: 'GR',
  griechenland: 'GR',
  grecia: 'GR',
  grekland: 'GR',
  grèce: 'GR',
  griekenland: 'GR',
  grécia: 'GR',
  greenland: 'GL',
  grönland: 'GL',
  groenlandia: 'GL',
  groenland: 'GL',
  gronelândia: 'GL',
  grenada: 'GD',
  granada: 'GD',
  grenade: 'GD',
  guadeloupe: 'GP',
  guadalupe: 'GP',
  guadalupa: 'GP',
  guam: 'GU',
  guatemala: 'GT',
  guernsey: 'GG',
  guernesey: 'GG',
  guinea: 'GN',
  guinée: 'GN',
  guinee: 'GN',
  guiné: 'GN',
  'guinea-bissau': 'GW',
  'guinea-bisáu': 'GW',
  'guinée-bissau': 'GW',
  'guinee-bissau': 'GW',
  'guiné-bissau': 'GW',
  guyana: 'GY',
  guiana: 'GY',
  haiti: 'HT',
  haití: 'HT',
  haïti: 'HT',
  'holy see (vatican city state)': 'VA',
  vatikan: 'VA',
  'santa sede': 'VA',
  'heliga stolen (vatikanstaten)': 'VA',
  'saint-siège (état de la cité du vatican)': 'VA',
  'heilige stoel (vaticaanstad)': 'VA',
  'santa sé (cidade do vaticano)': 'VA',
  honduras: 'HN',
  'hong kong': 'HK',
  hongkong: 'HK',
  hungary: 'HU',
  ungarn: 'HU',
  hungría: 'HU',
  ungheria: 'HU',
  ungern: 'HU',
  hongrie: 'HU',
  hongarije: 'HU',
  hungria: 'HU',
  iceland: 'IS',
  island: 'IS',
  islandia: 'IS',
  islanda: 'IS',
  islande: 'IS',
  ijsland: 'IS',
  islândia: 'IS',
  india: 'IN',
  indien: 'IN',
  inde: 'IN',
  índia: 'IN',
  indonesia: 'ID',
  indonesien: 'ID',
  indonésie: 'ID',
  indonesië: 'ID',
  indonésia: 'ID',
  iran: 'IR',
  irán: 'IR',
  irã: 'IR',
  iraq: 'IQ',
  irak: 'IQ',
  iraque: 'IQ',
  ireland: 'IE',
  irland: 'IE',
  irlanda: 'IE',
  irlande: 'IE',
  ierland: 'IE',
  'isle of man': 'IM',
  'insel man': 'IM',
  'isla de man': 'IM',
  'isola di man': 'IM',
  'isle of man (sometimes mann)': 'IM',
  'île de man': 'IM',
  'ilha de man': 'IM',
  israel: 'IL',
  israele: 'IL',
  israël: 'IL',
  italy: 'IT',
  italien: 'IT',
  italia: 'IT',
  italie: 'IT',
  italië: 'IT',
  itália: 'IT',
  jamaica: 'JM',
  jamaika: 'JM',
  giamaica: 'JM',
  jamaïque: 'JM',
  japan: 'JP',
  japón: 'JP',
  giappone: 'JP',
  japon: 'JP',
  japão: 'JP',
  jordan: 'JO',
  jordanien: 'JO',
  jordania: 'JO',
  giordania: 'JO',
  jordanie: 'JO',
  jordanië: 'JO',
  jordânia: 'JO',
  kazakhstan: 'KZ',
  kasachstan: 'KZ',
  kazajistán: 'KZ',
  kazakistan: 'KZ',
  kazakstan: 'KZ',
  kazachstan: 'KZ',
  cazaquistão: 'KZ',
  kenya: 'KE',
  kenia: 'KE',
  quênia: 'KE',
  kiribati: 'KI',
  "korea, democratic people's republic of": 'KP',
  nordkorea: 'KP',
  'corea del norte': 'KP',
  'corea del nord': 'KP',
  'corée du nord': 'KP',
  'noord-korea': 'KP',
  'coreia do norte': 'KP',
  'coreia do norte, república popular democrática da': 'KP',
  'korea, republic of': 'KR',
  südkorea: 'KR',
  'corea del sur': 'KR',
  'corea del sud': 'KR',
  sydkorea: 'KR',
  'corée du sud': 'KR',
  'zuid-korea': 'KR',
  'coreia do sul, república da': 'KR',
  kuwait: 'KW',
  koweït: 'KW',
  koeweit: 'KW',
  kyrgyzstan: 'KG',
  kirgisistan: 'KG',
  kirguistán: 'KG',
  kirghizistan: 'KG',
  kirgizistan: 'KG',
  kirgistan: 'KG',
  quirguistão: 'KG',
  "lao, people's democratic republic": 'LA',
  laos: 'LA',
  'laos, república democrática popular': 'LA',
  latvia: 'LV',
  lettland: 'LV',
  letonia: 'LV',
  lettonia: 'LV',
  lettonie: 'LV',
  letland: 'LV',
  letônia: 'LV',
  lebanon: 'LB',
  libanon: 'LB',
  líbano: 'LB',
  libano: 'LB',
  liban: 'LB',
  lesotho: 'LS',
  lesoto: 'LS',
  liberia: 'LR',
  libéria: 'LR',
  'libyan arab jamahiriya': 'LY',
  libyen: 'LY',
  libia: 'LY',
  libye: 'LY',
  libië: 'LY',
  líbia: 'LY',
  liechtenstein: 'LI',
  lithuania: 'LT',
  litauen: 'LT',
  lituania: 'LT',
  lituanie: 'LT',
  litouwen: 'LT',
  lituânia: 'LT',
  luxembourg: 'LU',
  luxemburg: 'LU',
  luxemburgo: 'LU',
  lussemburgo: 'LU',
  macau: 'MO',
  macao: 'MO',
  macedonia: 'MK',
  'macedonia, the former yugoslav republic of': 'MK',
  mazedonien: 'MK',
  makedonien: 'MK',
  macédoine: 'MK',
  'noord-macedonië': 'MK',
  macedônia: 'MK',
  'macedônia, antiga república jugoslava da': 'MK',
  madagascar: 'MG',
  madagaskar: 'MG',
  malawi: 'MW',
  malaysia: 'MY',
  malasia: 'MY',
  malaisie: 'MY',
  maleisië: 'MY',
  malásia: 'MY',
  maldives: 'MV',
  malediven: 'MV',
  maldivas: 'MV',
  maldive: 'MV',
  maldiverna: 'MV',
  mali: 'ML',
  malí: 'ML',
  malta: 'MT',
  malte: 'MT',
  'marshall islands': 'MH',
  marshallinseln: 'MH',
  'islas marshall': 'MH',
  'isole marshall': 'MH',
  marshallöarna: 'MH',
  'îles marshall': 'MH',
  marshalleilanden: 'MH',
  'ilhas marshall': 'MH',
  martinique: 'MQ',
  martinica: 'MQ',
  mauritania: 'MR',
  mauretanien: 'MR',
  mauritanie: 'MR',
  mauritanië: 'MR',
  mauritânia: 'MR',
  mauritius: 'MU',
  mauricio: 'MU',
  maurice: 'MU',
  maurícia: 'MU',
  mayotte: 'YT',
  mexico: 'MX',
  mexiko: 'MX',
  méxico: 'MX',
  messico: 'MX',
  mexique: 'MX',
  micronesia: 'FM',
  'micronesia, federated states of': 'FM',
  mikronesien: 'FM',
  micronésie: 'FM',
  micronésia: 'FM',
  estados: 'FM',
  moldova: 'MD',
  'moldova, republic of': 'MD',
  'moldau, republik': 'MD',
  'moldavia, república de': 'MD',
  'moldavia, repubblica di': 'MD',
  moldavien: 'MD',
  'moldavie, république de': 'MD',
  'moldavië, republiek': 'MD',
  'moldávia, república da': 'MD',
  monaco: 'MC',
  mónaco: 'MC',
  mônaco: 'MC',
  mongolia: 'MN',
  mongolei: 'MN',
  mongoliet: 'MN',
  mongolie: 'MN',
  mongolië: 'MN',
  mongólia: 'MN',
  montenegro: 'ME',
  monténégro: 'ME',
  montserrat: 'MS',
  morocco: 'MA',
  marokko: 'MA',
  marruecos: 'MA',
  marocco: 'MA',
  marocko: 'MA',
  maroc: 'MA',
  marrocos: 'MA',
  mozambique: 'MZ',
  mosambik: 'MZ',
  mozambico: 'MZ',
  moçambique: 'MZ',
  myanmar: 'MM',
  'myanmar (burma)': 'MM',
  birmanie: 'MM',
  namibia: 'NA',
  namibie: 'NA',
  namibië: 'NA',
  namíbia: 'NA',
  nauru: 'NR',
  nepal: 'NP',
  népal: 'NP',
  netherlands: 'NL',
  niederlande: 'NL',
  'países bajos': 'NL',
  olanda: 'NL',
  nederländerna: 'NL',
  'pays-bas': 'NL',
  nederland: 'NL',
  'países baixos': 'NL',
  'netherlands antilles (no longer exists)': 'AN',
  'niederländische antillen (existiert nicht mehr)': 'AN',
  'antillas neerlandesas (ya no existe)': 'AN',
  'antille olandesi (non esiste più)': 'AN',
  'nederländska antillerna (existerar inte längre)': 'AN',
  "antilles néerlandaises (n'existe plus)": 'AN',
  'nederlandse antillen (bestaat niet meer)': 'AN',
  'antilhas holandesas (não existe mais)': 'AN',
  'new caledonia': 'NC',
  neukaledonien: 'NC',
  'nueva caledonia': 'NC',
  'nuova caledonia': 'NC',
  'nya kaledonien': 'NC',
  'nouvelle-calédonie': 'NC',
  'nieuw-caledonië': 'NC',
  'nova caledônia': 'NC',
  'new zealand': 'NZ',
  neuseeland: 'NZ',
  'nueva zelanda': 'NZ',
  'nuova zelanda': 'NZ',
  'nya zeeland': 'NZ',
  'nouvelle-zélande': 'NZ',
  'nieuw-zeeland': 'NZ',
  'nova zelândia': 'NZ',
  nicaragua: 'NI',
  nicarágua: 'NI',
  niger: 'NE',
  níger: 'NE',
  nigeria: 'NG',
  nigéria: 'NG',
  niue: 'NU',
  'norfolk island': 'NF',
  norfolk: 'NF',
  'isla norfolk': 'NF',
  norfolköarna: 'NF',
  'île de norfolk': 'NF',
  'ilha norfolk': 'NF',
  'northern mariana islands': 'MP',
  'nördliche marianen': 'MP',
  'islas marianas del norte': 'MP',
  'isole marianne settentrionali': 'MP',
  nordmarianerna: 'MP',
  'îles mariannes du nord': 'MP',
  'noordelijke marianen': 'MP',
  'ilhas marianas do norte': 'MP',
  norway: 'NO',
  norwegen: 'NO',
  noruega: 'NO',
  norvegia: 'NO',
  norge: 'NO',
  norvège: 'NO',
  noorwegen: 'NO',
  oman: 'OM',
  omán: 'OM',
  omã: 'OM',
  pakistan: 'PK',
  pakistán: 'PK',
  paquistão: 'PK',
  palau: 'PW',
  palaos: 'PW',
  palestine: 'PS',
  palästina: 'PS',
  palestina: 'PS',
  panama: 'PA',
  panamá: 'PA',
  'papua new guinea': 'PG',
  'papua-neuguinea': 'PG',
  'papúa nueva guinea': 'PG',
  'papua nuova guinea': 'PG',
  'papua nya guinea': 'PG',
  'papouasie-nouvelle-guinée': 'PG',
  'papoea-nieuw-guinea': 'PG',
  'papua-nova guiné': 'PG',
  paraguay: 'PY',
  paraguai: 'PY',
  peru: 'PE',
  perú: 'PE',
  perù: 'PE',
  pérou: 'PE',
  philippines: 'PH',
  philippinen: 'PH',
  filipinas: 'PH',
  filippine: 'PH',
  filippinerna: 'PH',
  filipijnen: 'PH',
  pitcairn: 'PN',
  poland: 'PL',
  polen: 'PL',
  polonia: 'PL',
  pologne: 'PL',
  polônia: 'PL',
  portugal: 'PT',
  portogallo: 'PT',
  'puerto rico': 'PR',
  'porto rico': 'PR',
  qatar: 'QA',
  katar: 'QA',
  reunion: 'RE',
  réunion: 'RE',
  reunión: 'RE',
  'la réunion': 'RE',
  reunião: 'RE',
  romania: 'RO',
  rumänien: 'RO',
  rumania: 'RO',
  roumanie: 'RO',
  roemenië: 'RO',
  romênia: 'RO',
  russia: 'RU',
  russland: 'RU',
  rusia: 'RU',
  ryssland: 'RU',
  russie: 'RU',
  rusland: 'RU',
  rússia: 'RU',
  rwanda: 'RW',
  ruanda: 'RW',
  'saint helena': 'SH',
  'st. helena': 'SH',
  'santa helena': 'SH',
  "sant'elena": 'SH',
  'sankta helena': 'SH',
  'sainte-hélène': 'SH',
  'sint helena': 'SH',
  'saint kitts and nevis': 'KN',
  'st. kitts und nevis': 'KN',
  'san cristóbal y nieves': 'KN',
  'saint kitts e nevis': 'KN',
  'saint kitts och nevis': 'KN',
  'saint-christophe-et-niévès': 'KN',
  'saint kitts en nevis': 'KN',
  'são cristóvão e nevis': 'KN',
  'saint lucia': 'LC',
  'st. lucia': 'LC',
  'santa lucía': 'LC',
  'santa lucia': 'LC',
  'sainte-lucie': 'LC',
  'santa lúcia': 'LC',
  'saint pierre and miquelon': 'PM',
  'saint-pierre und miquelon': 'PM',
  'san pedro y miquelón': 'PM',
  'saint-pierre e miquelon': 'PM',
  'saint-pierre och miquelon': 'PM',
  'saint-pierre-et-миquelon': 'PM',
  'saint-pierre en miquelon': 'PM',
  'saint vincent and the grenadines': 'VC',
  'st. vincent und die grenadinen': 'VC',
  'san vicente y las granadinas': 'VC',
  'saint vincent e grenadine': 'VC',
  'saint vincent och grenadinerna': 'VC',
  'saint-vincent-et-les grenadines': 'VC',
  'saint vincent en de grenadinen': 'VC',
  'são vicente e granadinas': 'VC',
  samoa: 'WS',
  'san marino': 'SM',
  'saint-marin': 'SM',
  'sao tome and principe': 'ST',
  'são tomé und príncipe': 'ST',
  'santo tomé y príncipe': 'ST',
  'são tomé e príncipe': 'ST',
  'são tomé och príncipe': 'ST',
  'sao tomé-et-principe': 'ST',
  'sao tomé en principe': 'ST',
  'saudi arabia': 'SA',
  'saudi-arabien': 'SA',
  'arabia saudita': 'SA',
  saudiarabien: 'SA',
  'arabie saoudite': 'SA',
  'saoedi-arabië': 'SA',
  'arábia saudita': 'SA',
  senegal: 'SN',
  sénégal: 'SN',
  serbia: 'RS',
  serbien: 'RS',
  serbie: 'RS',
  servië: 'RS',
  sérvia: 'RS',
  seychelles: 'SC',
  seychellen: 'SC',
  seychellerna: 'SC',
  'sierra leone': 'SL',
  'sierra leona': 'SL',
  'serra leoa': 'SL',
  singapore: 'SG',
  singapur: 'SG',
  singapour: 'SG',
  singapura: 'SG',
  slovakia: 'SK',
  slowakei: 'SK',
  eslovaquia: 'SK',
  slovacchia: 'SK',
  slovakien: 'SK',
  slovaquie: 'SK',
  slowakije: 'SK',
  eslováquia: 'SK',
  slovenia: 'SI',
  slowenien: 'SI',
  eslovenia: 'SI',
  slovenien: 'SI',
  slovénie: 'SI',
  slovenië: 'SI',
  eslovênia: 'SI',
  'solomon islands': 'SB',
  salomonen: 'SB',
  'islas salomón': 'SB',
  'isole salomone': 'SB',
  salomonöarna: 'SB',
  'îles salomon': 'SB',
  salomonseilanden: 'SB',
  'ilhas salomão': 'SB',
  somalia: 'SO',
  somalie: 'SO',
  somalië: 'SO',
  somália: 'SO',
  'south africa': 'ZA',
  südafrika: 'ZA',
  sudáfrica: 'ZA',
  sudafrica: 'ZA',
  sydafrika: 'ZA',
  'afrique du sud': 'ZA',
  'zuid-afrika': 'ZA',
  'áfrica do sul': 'ZA',
  'south georgia and the south sandwich islands': 'GS',
  'südgeorgien und die südlichen sandwichinseln': 'GS',
  'georgia del sur y las islas sandwich del sur': 'GS',
  'georgia del sud e isole sandwich australi': 'GS',
  'sydgeorgien och sydsandwichöarna': 'GS',
  'géorgie du sud-et-les îles sandwich du sud': 'GS',
  'zuid-georgië en de zuidelijke sandwicheilanden': 'GS',
  'geórgia do sul e ilhas sandwich do sul': 'GS',
  spain: 'ES',
  spanien: 'ES',
  españa: 'ES',
  spagna: 'ES',
  espagne: 'ES',
  spanje: 'ES',
  espanha: 'ES',
  'sri lanka': 'LK',
  sudan: 'SD',
  sudán: 'SD',
  soudan: 'SD',
  soedan: 'SD',
  sudão: 'SD',
  suriname: 'SR',
  surinam: 'SR',
  'svalbard and jan mayen': 'SJ',
  'svalbard und jan mayen': 'SJ',
  'svalbard y jan mayen': 'SJ',
  'svalbard e jan mayen': 'SJ',
  'svalbard och jan mayen': 'SJ',
  'svalbard et jan mayen': 'SJ',
  'svalbard en jan mayen': 'SJ',
  'eswatini (formerly swaziland)': 'SZ',
  'swasiland (ehemals swasiland)': 'SZ',
  'swazilandia (anteriormente swazilandia)': 'SZ',
  'swaziland (precedentemente swaziland)': 'SZ',
  'swaziland (tidigare swaziland)': 'SZ',
  'swaziland (anciennement swaziland)': 'SZ',
  'swaziland (voorheen swaziland)': 'SZ',
  'suazilândia (anteriormente swazilândia)': 'SZ',
  sweden: 'SE',
  schweden: 'SE',
  suecia: 'SE',
  svezia: 'SE',
  sverige: 'SE',
  suède: 'SE',
  zweden: 'SE',
  suécia: 'SE',
  switzerland: 'CH',
  schweiz: 'CH',
  suiza: 'CH',
  svizzera: 'CH',
  suisse: 'CH',
  zwitserland: 'CH',
  suíça: 'CH',
  'syrian arab republic': 'SY',
  syrien: 'SY',
  'república árabe siria': 'SY',
  siria: 'SY',
  'république arabe syrienne': 'SY',
  syrië: 'SY',
  'república árabe da síria': 'SY',
  taiwan: 'TW',
  taiwán: 'TW',
  taïwan: 'TW',
  tajikistan: 'TJ',
  tadschikistan: 'TJ',
  tayikistán: 'TJ',
  tagikistan: 'TJ',
  tadzjikistan: 'TJ',
  tadjikistan: 'TJ',
  tajiquistão: 'TJ',
  'tanzania, united republic of': 'TZ',
  'tansania, vereinigte republik': 'TZ',
  'tanzania, república unida de': 'TZ',
  'tanzania, repubblica unita di': 'TZ',
  'tanzania, förenade republik': 'TZ',
  'tanzanie, république-unie de': 'TZ',
  'tanzania, verenigde republiek': 'TZ',
  'tanzânia, república unida da': 'TZ',
  thailand: 'TH',
  tailandia: 'TH',
  thailandia: 'TH',
  thaïlande: 'TH',
  tailândia: 'TH',
  'timor-leste (formerly east timor)': 'TL',
  'osttimor (ehemals osttimor)': 'TL',
  'timor oriental (anteriormente timor oriental)': 'TL',
  'timor est (precedentemente timor est)': 'TL',
  'östtimor (tidigare östtimor)': 'TL',
  'timor oriental (anciennement timor oriental)': 'TL',
  'oost-timor (voorheen oost-timor)': 'TL',
  'timor-leste (anteriormente timor leste)': 'TL',
  togo: 'TG',
  tokelau: 'TK',
  tonga: 'TO',
  'trinidad and tobago': 'TT',
  'trinidad und tobago': 'TT',
  'trinidad y tobago': 'TT',
  'trinidad e tobago': 'TT',
  'trinidad och tobago': 'TT',
  'trinité-et-tobago': 'TT',
  'trinidad en tobago': 'TT',
  tunisia: 'TN',
  tunesien: 'TN',
  túnez: 'TN',
  tunisien: 'TN',
  tunisie: 'TN',
  tunesië: 'TN',
  tunísia: 'TN',
  turkey: 'TR',
  türkei: 'TR',
  turquía: 'TR',
  turchia: 'TR',
  turkiet: 'TR',
  turquie: 'TR',
  turkije: 'TR',
  turquia: 'TR',
  turkmenistan: 'TM',
  turkmenistán: 'TM',
  turkménistan: 'TM',
  turcomenistão: 'TM',
  'turks and caicos islands': 'TC',
  'turks- und caicosinseln': 'TC',
  'islas turcas y caicos': 'TC',
  'isole turks e caicos': 'TC',
  'turks- och caicosöarna': 'TC',
  'îles turques-et-caïques': 'TC',
  'turks- en caicoseilanden': 'TC',
  'ilhas turcas e caicos': 'TC',
  tuvalu: 'TV',
  uganda: 'UG',
  ouganda: 'UG',
  oeganda: 'UG',
  ukraine: 'UA',
  ucrania: 'UA',
  ucraina: 'UA',
  ukraina: 'UA',
  oekraïne: 'UA',
  ucrânia: 'UA',
  'united arab emirates': 'AE',
  'vereinigte arabische emirate': 'AE',
  'emiratos árabes unidos': 'AE',
  'emirati arabi uniti': 'AE',
  'förenade arabemiraten': 'AE',
  'émirats arabes unis': 'AE',
  'verenigde arabische emiraten': 'AE',
  'emirados árabes unidos': 'AE',
  'united kingdom': 'GB',
  'vereinigtes königreich': 'GB',
  'reino unido': 'GB',
  'regno unito': 'GB',
  storbritannien: 'GB',
  'royaume-uni': 'GB',
  'verenigd koninkrijk': 'GB',
  'united states': 'US',
  'vereinigte staaten': 'US',
  'estados unidos': 'US',
  'stati uniti': 'US',
  'usa (amerikas förenta stater)': 'US',
  'états-unis': 'US',
  'verenigde staten': 'US',
  'united states minor outlying islands': 'UM',
  'kleinere inselbesitzungen der vereinigten staaten': 'UM',
  'islas menores periféricas de los estados unidos': 'UM',
  'isole minori lontane dagli stati uniti': 'UM',
  'usa:s mindre utkanter': 'UM',
  'îles mineures éloignées des états-unis': 'UM',
  'kleine afgelegen eilanden van de verenigde staten': 'UM',
  'ilhas menores distantes dos estados unidos': 'UM',
  uruguay: 'UY',
  uruguai: 'UY',
  uzbekistan: 'UZ',
  usbekistan: 'UZ',
  uzbekistán: 'UZ',
  ouzbékistan: 'UZ',
  oezbekistan: 'UZ',
  uzbequistão: 'UZ',
  vanuatu: 'VU',
  venezuela: 'VE',
  vietnam: 'VN',
  'viêt nam': 'VN',
  vietnã: 'VN',
  'virgin islands (british)': 'VG',
  'britische jungferninseln': 'VG',
  'islas vírgenes británicas': 'VG',
  'isole vergini britanniche': 'VG',
  'brittiska jungfruöarna': 'VG',
  'îles vierges britanniques': 'VG',
  'britse maagdeneilanden': 'VG',
  'ilhas virgens britânicas': 'VG',
  'virgin islands (u.s.)': 'VI',
  'amerikanische jungferninseln': 'VI',
  'islas vírgenes estadounidenses': 'VI',
  'isole vergini americane': 'VI',
  'amerikanska jungfruöarna': 'VI',
  'îles vierges des états-unis': 'VI',
  'amerikaanse maagdeneilanden': 'VI',
  'ilhas virgens americanas': 'VI',
  'wallis and futuna islands': 'WF',
  'wallis und futuna': 'WF',
  'wallis y futuna': 'WF',
  'wallis e futuna': 'WF',
  'wallis och futuna': 'WF',
  'wallis-et-futuna': 'WF',
  'wallis en futuna': 'WF',
  'western sahara': 'EH',
  westsahara: 'EH',
  'sahara occidental': 'EH',
  'sahara occidentale': 'EH',
  västsahara: 'EH',
  'westelijke sahara': 'EH',
  'saara ocidental': 'EH',
  yemen: 'YE',
  jemen: 'YE',
  yémen: 'YE',
  iêmen: 'YE',
  zambia: 'ZM',
  sambia: 'ZM',
  zambie: 'ZM',
  zâmbia: 'ZM',
  zimbabwe: 'ZW',
  simbabwe: 'ZW',
  zimbabue: 'ZW',
};
