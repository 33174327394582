import qs from 'qs';
import http, { StrapiDTO, StrapiResponseDTO, useAxiosUtils } from './axiosClient';
import { useHandleError } from './logErrors';
import { PaginationOptions } from './useLeadsTable';
import { LinkMate } from './linkMateService';
import { ImageLink } from './userDataService';
import { ref } from 'vue';

export type AddLoginPointToLeaderboardEntryResponse = {
  leaderboardEntry: LeaderBoardEntry;
  hasPointBeenAdded: boolean;
};

export type LeaderBoardTimeRange = {
  isFirstDayOfMonth: boolean;
  startDate: string;
  endDate: string;
};

export type MyLeaderboardRanking = {
  myRanking: number;
  myLeaderboardEntry: LeaderBoardEntry;
  isShowingPreviousMonth: boolean;
  pointsDifference: number;
};

export type UserEntry = {
  id: number;
  firstName: string;
  lastName: string;
  profileTree: Pick<LinkMate, 'linkName' | 'image'>;
};

type UserEntryFindMany = {
  data: StrapiDTO<{
    firstName: string;
    lastName: string;
    profileTree: {
      data: StrapiDTO<{
        id: number;
        linkName: string;
        image: {
          data: StrapiDTO<ImageLink>;
        };
      }>;
    };
  }>;
};

export type LeaderBoardEntryResponseWithProfileTree = LeaderBoardObject<UserEntryFindMany>;

export type FindManyLeaderboardResponse = StrapiDTO<LeaderBoardEntryResponseWithProfileTree>[];

export type LeaderBoardEntry = LeaderBoardObject<UserEntry>;

// TODO type this
export type LeaderBoardObject<T> = {
  id: number;
  challengeName: string;
  lastLoginTimestamp: string;
  createdAt: string;
  points: number;
  userId: T;
};

export const LeaderBoardEntryPlaceholder: LeaderBoardEntry = {
  challengeName: 'GeneralLeaderboardChallenge',
  lastLoginTimestamp: '',
  id: 0,
  createdAt: '',
  points: 0,
  userId: {
    firstName: '',
    id: 0,
    lastName: '',
    profileTree: {
      linkName: '',
      image: {
        url: '',
      },
    },
  },
};

export function useLeaderboardService() {
  const { getHeaders } = useAxiosUtils();
  const { throwError } = useHandleError();

  async function getLeaderboard(challengeName: string, paginationOptions: PaginationOptions) {
    const query = qs.stringify(
      {
        filters: {
          challengeName: {
            $eq: challengeName,
          },
        },
        populate: {
          userId: {
            fields: ['firstName', 'lastName'],
            populate: {
              profileTree: {
                fields: ['linkName'],
                populate: {
                  image: true,
                },
              },
            },
          },
        },
        pagination: {
          start: paginationOptions.start,
          limit: paginationOptions.limit,
        },
      },
      { encodeValuesOnly: true }
    );
    try {
      const resp = await http.get<StrapiResponseDTO<FindManyLeaderboardResponse>>(
        `/api/leaderboards/get-leaderboard-entries?${query}`,
        getHeaders()
      );

      const newEntries = mapLeaderboardEntries(resp.data.data);

      return {
        data: newEntries,
        meta: resp.data.meta,
      };
    } catch (error) {
      throwError(error);
    }
  }

  async function getLeaderboardTimeFrame() {
    try {
      const resp = await http.get<LeaderBoardTimeRange>(`api/leaderboards/challenge-time-range`, getHeaders());
      return resp.data;
    } catch (error) {
      throwError(error);
    }
  }

  async function getMyRanking(challengeName?: string, showCurrentMonthStats: boolean = false) {
    const challengeNameInternal = challengeName || 'GeneralLeaderboardChallenge';
    try {
      const resp = await http.get<MyLeaderboardRanking>(
        `/api/leaderboards/my-ranking?challengeName=${challengeNameInternal}${showCurrentMonthStats ? `&showCurrentMonthStats=${showCurrentMonthStats}` : ''}`,
        getHeaders()
      );

      return resp.data;
    } catch (error) {
      throwError(error);
    }
  }

  const mapLeaderboardEntries = (entries: FindManyLeaderboardResponse): LeaderBoardEntry[] => {
    const newEntries = entries.map(entry => {
      const userEntry: UserEntry = {
        id: entry.attributes.userId.data.id,
        firstName: entry.attributes.userId.data.attributes.firstName,
        lastName: entry.attributes.userId.data.attributes.lastName,
        profileTree: {
          linkName: entry.attributes.userId?.data?.attributes?.profileTree?.data?.attributes?.linkName || '',
          image: {
            ...(entry.attributes.userId?.data?.attributes?.profileTree?.data?.attributes?.image?.data?.attributes || {}),
          },
        },
      };

      return {
        id: entry.id,
        challengeName: entry.attributes.challengeName,
        lastLoginTimestamp: entry.attributes.lastLoginTimestamp,
        createdAt: entry.attributes.createdAt,
        points: entry.attributes.points,
        userId: userEntry,
      };
    });
    return newEntries;
  };

  async function addLoginPointToLeaderboardEntry(userId: number, challengeName?: string) {
    const challengeNameInternal = challengeName || 'GeneralLeaderboardChallenge';
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const payload = {
      data: {
        userId: userId,
        challengeName: challengeNameInternal,
        clientTimeZone,
      },
    };
    try {
      const resp = await http.post<AddLoginPointToLeaderboardEntryResponse>(`/api/leaderboards/create-login-points`, payload, getHeaders());
      return resp.data.hasPointBeenAdded;
    } catch (error) {
      throwError(error);
    }
  }

  const previousMonth = ref((new Date().getUTCMonth() - 1 + 12) % 12);

  return {
    getLeaderboard,
    addLoginPointToLeaderboardEntry,
    getLeaderboardTimeFrame,
    getMyRanking,
    previousMonth,
  };
}
