export default `<svg viewBox="0 0 36 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2817_19634)">
<path d="M0 0H42.78V28.52H0V0Z" fill="white"/>
<path d="M0 0H11.8416V28.52H0V0ZM30.9384 0H42.78V28.52H30.9384V0ZM25.5596 17.1787L29.7625 15.0772L27.6611 14.0265V11.925L23.4581 14.0265L25.5596 9.82356H23.4581L21.3566 6.67135L19.2552 9.82356H17.1537L19.2552 14.0265L15.0522 11.925V14.0265L12.9507 15.0772L17.1537 17.1787L16.103 19.2802H20.3059V22.4324H22.4074V19.2802H26.6103L25.5596 17.1787Z" fill="#FF0000"/>
<path d="M0.00014329 0L21.3862 0V28.5148H0.00014329V0Z" fill="white"/>
<path d="M0.00014329 0L21.3862 0V2.1928H0.00014329V0ZM0.00014329 4.38561H21.3862V6.57841H0.00014329V4.38561ZM0.00014329 8.77121H21.3862V10.964H0.00014329V8.77121ZM0.00014329 13.1568H21.3862V15.3496H0.00014329V13.1568ZM0.00014329 17.5508H21.3862V19.7436H0.00014329V17.5508ZM0.00014329 21.9364H21.3862V24.1292H0.00014329V21.9364ZM0.00014329 26.322H21.3862V28.5148H0.00014329V26.322Z" fill="#D80027"/>
<path d="M0.00014329 0L21.3862 0V15.3496H0.00014329V0Z" fill="#2E52B2"/>
<path d="M3.98513 11.5812L3.65162 10.514L3.28477 11.5812H2.1842L3.07633 12.2232L2.74282 13.2904L3.65162 12.6318L4.53541 13.2904L4.19357 12.2232L5.10238 11.5812H3.98513ZM8.67923 11.5812L8.33739 10.514L7.98721 11.5812H6.88664L7.77876 12.2232L7.44526 13.2904L8.33739 12.6318L9.23786 13.2904L8.90435 12.2232L9.79648 11.5812H8.67923ZM13.39 11.5812L13.0315 10.514L12.698 11.5812H11.5724L12.4895 12.2232L12.1394 13.2904L13.0315 12.6318L13.9486 13.2904L13.5984 12.2232L14.4906 11.5812H13.39ZM18.0758 11.5812L17.7423 10.514L17.3921 11.5812H16.2832L17.1836 12.2232L16.8501 13.2904L17.7423 12.6318L18.6427 13.2904L18.2842 12.2232L19.2014 11.5812H18.0758ZM8.33739 6.27847L7.98721 7.34569H6.88664L7.77876 8.00436L7.44526 9.05491L8.33739 8.40457L9.23786 9.05491L8.90435 8.00436L9.79648 7.34569H8.67923L8.33739 6.27847ZM3.65162 6.27847L3.28477 7.34569H2.1842L3.07633 8.00436L2.74282 9.05491L3.65162 8.40457L4.53541 9.05491L4.19357 8.00436L5.10238 7.34569H3.98513L3.65162 6.27847ZM13.0315 6.27847L12.698 7.34569H11.5724L12.4895 8.00436L12.1394 9.05491L13.0315 8.40457L13.9486 9.05491L13.5984 8.00436L14.4906 7.34569H13.39L13.0315 6.27847ZM17.7423 6.27847L17.3921 7.34569H16.2832L17.1836 8.00436L16.8501 9.05491L17.7423 8.40457L18.6427 9.05491L18.2842 8.00436L19.2014 7.34569H18.0758L17.7423 6.27847ZM3.65162 2.05961L3.28477 3.11016H2.1842L3.07633 3.76883L2.74282 4.82772L3.65162 4.16904L4.53541 4.82772L4.19357 3.76883L5.10238 3.11016H3.98513L3.65162 2.05961ZM8.33739 2.05961L7.98721 3.11016H6.88664L7.77876 3.76883L7.44526 4.82772L8.33739 4.16904L9.23786 4.82772L8.90435 3.76883L9.79648 3.11016H8.67923L8.33739 2.05961ZM13.0315 2.05961L12.698 3.11016H11.5724L12.4895 3.76883L12.1394 4.82772L13.0315 4.16904L13.9486 4.82772L13.5984 3.76883L14.4906 3.11016H13.39L13.0315 2.05961ZM17.7423 2.05961L17.3921 3.11016H16.2832L17.1836 3.76883L16.8501 4.82772L17.7423 4.16904L18.6427 4.82772L18.2842 3.76883L19.2014 3.11016H18.0758L17.7423 2.05961Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_2817_19634">
<rect width="42.78" height="28.52" fill="white"/>
</clipPath>
</defs>
</svg>
`;
