import http, { StrapiDTO, StrapiObjectBody, StrapiResponseDTO, useAxiosUtils } from './axiosClient';
import { LinkMateLink } from './linkMateLinkService';
import { ImageLink } from './userDataService';
import { AccountLanguage } from './useLanguageService';
import qs from 'qs';
import { useUtilsService } from './utilsService';
import { TenantDTO } from './tenantService';

export const FontsArray = ['montserrat', 'roboto_slab', 'noto_sans', 'lato', 'monda', 'candara'] as const;
export type Fonts = (typeof FontsArray)[number];

export const ShapeArray = ['pill', 'squared', 'rounded'] as const;
export type Shape = (typeof ShapeArray)[number];

export const LinkMateColorsArray = ['green', 'orange', 'blue', 'purple', 'indigo', 'gray'] as const;
export type LinkMateColors = (typeof LinkMateColorsArray)[number];

export const layoutArray = ['top', 'bottom'] as const;
export type Layout = (typeof layoutArray)[number];

export const wallpaperArray = [
  'wallpaper1',
  'wallpaper2',
  'wallpaper3',
  'wallpaper4',
  'wallpaper5',
  'wallpaper6',
  'wallpaper7',
  'wallpaper8',
  'wallpaper9',
  'wallpaper10',
  'wallpaper11',
  'wallpaper12',
  'wallpaper13',
  'wallpaper14',
  'wallpaper15',
  'wallpaper16',
  'wallpaper17',
  'wallpaper18',
  'wallpaper19',
  'wallpaper20',
  'wallpaper21',
  'wallpaper22',
  'wallpaper23',
  'wallpaper24',
  'wallpaper25',
  'wallpaper26',
] as const;
export type Wallpaper = (typeof wallpaperArray)[number];

export interface LinkMate {
  linkName: string;
  image?: ImageLink;
  title?: string;
  subTitle?: string;
  bio?: string;
  contactMeFormEnabled?: boolean;
  contactMeFormLanguage?: AccountLanguage;
  layout?: Layout;
  linkShape?: Shape;
  templateMessageChosen?: 'default' | 'friendly' | 'formal' | 'motivational';
  wallpaper?: string | null;
  color?: LinkMateColors;
  fontName?: Fonts;
  profileLinks?: StrapiObjectBody<StrapiDTO<LinkMateLink>[]>;
  onboardingType?: 'from_scratch' | 'fast';
  onboardingPhase?: number;
  contactPreference?: 'whatsapp' | 'sms';
}

export type LinkMateStyleUpdateWithLinkName = Pick<LinkMate, 'color' | 'wallpaper' | 'linkShape' | 'fontName'> & { linkName?: string };

export interface LinkMateWithId extends LinkMate {
  id: number;
}

export interface LinkMateWithUserId extends LinkMate {
  userId: StrapiObjectBody<{
    id: number;
    attributes: { phoneNumber?: string; tenant: StrapiObjectBody<StrapiDTO<TenantDTO>>; countryCode?: string };
  }>;
}

export type LinkMateFullPopulated = Omit<LinkMateWithUserId, 'image'> & {
  image: StrapiObjectBody<StrapiDTO<ImageLink> | undefined>;
};

export interface LinkMateWithUserIdPost extends Omit<LinkMate, 'profileLinks'> {
  userId: number;
}

export const PLACEHOLDERTEXT = '{{linkname}}';

export function isLinkMateWithId(linkMate: LinkMateWithId | LinkMateFullPopulated): linkMate is LinkMateWithId {
  return (linkMate as LinkMateWithId).id !== undefined;
}

export function useLinkMateService() {
  const { getHeaders, getApiToken } = useAxiosUtils();
  const { computeColor } = useUtilsService();

  async function createLinkMate(payload: LinkMateWithUserIdPost) {
    const res = await http.post<StrapiResponseDTO<StrapiDTO<LinkMate>>>(`api/profile-trees/`, { data: { ...payload } }, getHeaders());
    return res;
  }

  async function getLinkMate(id: number) {
    const res = await http.get<StrapiResponseDTO<StrapiDTO<LinkMateFullPopulated>>>(
      `/api/profile-trees/${id}?populate=profileLinks&populate=image`,
      getHeaders()
    );
    return res.data.data;
  }

  async function updateLinkMateStyleAndLinkName(id: number, payload: LinkMateStyleUpdateWithLinkName) {
    const res = await http.put<StrapiResponseDTO<StrapiDTO<LinkMate>>>(`/api/profile-trees/${id}`, { data: { ...payload } }, getHeaders());
    return res.data.data;
  }

  async function doesProfileNicknameExist(linkName: string) {
    const query = qs.stringify(
      {
        filters: {
          linkName: {
            $eqi: linkName,
          },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    const res = await http.get<StrapiResponseDTO<StrapiDTO<LinkMate>[]>>(`/api/profile-trees?${query}`, getHeaders());
    return res.data.data.length === 1;
  }

  async function getLinkMateFromLinkNameWithAPIKey(linkName: string) {
    const query = qs.stringify(
      {
        filters: {
          linkName: {
            $eq: linkName,
          },
        },
        populate: {
          image: {
            fields: ['*'],
          },
          profileLinks: {
            fields: ['*'],
          },
          userId: {
            populate: ['tenant'],
            fields: ['id', 'phoneNumber', 'tenant', 'countryCode'],
          },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    const res = await http.get<StrapiResponseDTO<StrapiDTO<LinkMateFullPopulated>[]>>(`/api/profile-trees/?${query}`, getApiToken());
    if (res.data.data && res.data.data.length) {
      return res.data.data[0].attributes;
    }
    return undefined;
  }

  async function updateLinkMate(id: number, payload: Partial<LinkMate>) {
    const res = await http.put<StrapiResponseDTO<StrapiDTO<LinkMate>>>(`/api/profile-trees/${id}`, { data: { ...payload } }, getHeaders());
    return res.data.data;
  }

  const sendTestEmail = async (leadEmail: string, leadLang: string, leadName: string, leadMagnetType: string, userId: number) => {
    return await http.post(
      `/api/user-emails/test-email`,
      {
        data: {
          leadEmail,
          leadLang,
          leadName,
          lastInteraction: leadMagnetType,
          userId,
        },
      },
      getHeaders()
    );
  };

  return {
    createLinkMate,
    getLinkMate,
    doesProfileNicknameExist,
    updateLinkMateStyleAndLinkName,
    getLinkMateFromLinkNameWithAPIKey,
    updateLinkMate,
    PLACEHOLDERTEXT,
    sendTestEmail,
  };
}
