export default `<svg viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.4329 31.7983L28.855 34.9288L17.8444 53.9998L14.6841 46.9517L23.4329 31.7983Z" fill="#BFBFBF"/>
<path d="M23.4329 31.7983L18.0107 28.6679L7.00007 47.7389L14.6841 46.9517L23.4329 31.7983Z" fill="#979797"/>
<path d="M31.1682 31.7983L25.7461 34.9288L36.7567 53.9998L39.917 46.9517L31.1682 31.7983Z" fill="#979797"/>
<path d="M31.1682 31.7983L36.5904 28.6679L47.601 47.7389L39.917 46.9517L31.1682 31.7983Z" fill="#BFBFBF"/>
<ellipse cx="27.2919" cy="18.6674" rx="18.6674" ry="18.6674" fill="url(#paint0_linear_1201_7791)"/>
<ellipse cx="27.2918" cy="18.6675" rx="14.5716" ry="14.5716" fill="#595959"/>
<mask id="mask0_1201_7791" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="13" y="5" width="30" height="30">
<ellipse cx="28.0696" cy="20.2232" rx="14.6055" ry="14.6055" fill="#C28B37"/>
</mask>
<g mask="url(#mask0_1201_7791)">
<ellipse cx="27.2918" cy="18.6675" rx="14.6055" ry="14.6055" fill="url(#paint1_linear_1201_7791)"/>
</g>
<g filter="url(#filter0_i_1201_7791)">
<path d="M21.8963 26.9491V24.826L27.5288 19.3044C28.0675 18.7605 28.5164 18.2772 28.8755 17.8547C29.2347 17.4322 29.504 17.0229 29.6836 16.6268C29.8631 16.2307 29.9529 15.8082 29.9529 15.3593C29.9529 14.847 29.8367 14.4087 29.6044 14.0443C29.372 13.6746 29.0525 13.3894 28.6458 13.1887C28.2391 12.988 27.777 12.8877 27.2595 12.8877C26.7261 12.8877 26.2587 12.9986 25.8573 13.2204C25.4559 13.4369 25.1443 13.7459 24.9225 14.1473C24.706 14.5486 24.5977 15.0266 24.5977 15.5811H21.8013C21.8013 14.5513 22.0363 13.6561 22.5063 12.8956C22.9764 12.1351 23.6233 11.5462 24.4472 11.129C25.2764 10.7118 26.227 10.5032 27.2991 10.5032C28.387 10.5032 29.3429 10.7065 30.1668 11.1132C30.9907 11.5198 31.6297 12.077 32.0839 12.7847C32.5434 13.4924 32.7731 14.3004 32.7731 15.2088C32.7731 15.8161 32.6569 16.4129 32.4246 16.9991C32.1922 17.5854 31.7829 18.235 31.1967 18.9479C30.6157 19.6609 29.7998 20.5244 28.7488 21.5384L25.9524 24.3824V24.4933H33.0187V26.9491H21.8963Z" fill="#D3D3D3"/>
</g>
<defs>
<filter id="filter0_i_1201_7791" x="21.8013" y="10.5032" width="11.2175" height="20.4458" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_1201_7791"/>
</filter>
<linearGradient id="paint0_linear_1201_7791" x1="45.8124" y1="16.7522" x2="14.8941" y2="31.8824" gradientUnits="userSpaceOnUse">
<stop stop-color="#D9D9D9"/>
<stop offset="1" stop-color="#737373"/>
</linearGradient>
<linearGradient id="paint1_linear_1201_7791" x1="27.2918" y1="4.06201" x2="27.2918" y2="33.273" gradientUnits="userSpaceOnUse">
<stop stop-color="#9CA1A3"/>
<stop offset="1" stop-color="#9CA1A3" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>
`;
