export default `<svg viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.4327 31.7986L28.8549 34.9291L17.8442 54L14.684 46.9519L23.4327 31.7986Z" fill="#E29339"/>
<path d="M23.4327 31.7986L18.0106 28.6681L6.99996 47.7391L14.684 46.9519L23.4327 31.7986Z" fill="#A45117"/>
<path d="M31.1682 31.7986L25.7461 34.9291L36.7567 54L39.917 46.9519L31.1682 31.7986Z" fill="#A45117"/>
<path d="M31.1682 31.7986L36.5904 28.6681L47.601 47.7391L39.917 46.9519L31.1682 31.7986Z" fill="#E29339"/>
<circle cx="27.2919" cy="18.6674" r="18.6674" fill="url(#paint0_linear_1201_7803)"/>
<circle cx="27.2918" cy="18.6673" r="14.5716" fill="#734C12"/>
<mask id="mask0_1201_7803" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="13" y="5" width="30" height="30">
<circle cx="28.0696" cy="20.2229" r="14.6055" fill="#C28B37"/>
</mask>
<g mask="url(#mask0_1201_7803)">
<circle cx="27.2918" cy="18.667" r="14.6055" fill="url(#paint1_linear_1201_7803)"/>
</g>
<g filter="url(#filter0_i_1201_7803)">
<path d="M27.5361 26.9713C26.3734 26.9713 25.3399 26.7785 24.4356 26.3929C23.5367 26.0072 22.8262 25.4705 22.3041 24.7827C21.7819 24.0948 21.5047 23.3001 21.4724 22.3986H24.5083C24.5352 22.8311 24.6832 23.2089 24.9524 23.532C25.2215 23.8499 25.5795 24.0974 26.0263 24.2746C26.473 24.4518 26.9736 24.5404 27.5281 24.5404C28.1202 24.5404 28.645 24.4413 29.1025 24.2433C29.5601 24.0401 29.918 23.7587 30.1764 23.3991C30.4348 23.0396 30.5613 22.6253 30.5559 22.1563C30.5613 21.6717 30.4321 21.2444 30.1683 20.8744C29.9046 20.5044 29.5224 20.2152 29.0218 20.0068C28.5266 19.7984 27.9291 19.6941 27.2293 19.6941H25.7679V17.4586H27.2293C27.8053 17.4586 28.3086 17.3622 28.7392 17.1694C29.1752 16.9766 29.517 16.7056 29.7646 16.3565C30.0122 16.0021 30.1333 15.5931 30.1279 15.1293C30.1333 14.6759 30.0284 14.2825 29.813 13.949C29.6031 13.6103 29.3044 13.3471 28.9168 13.1595C28.5346 12.972 28.0852 12.8782 27.5684 12.8782C27.0624 12.8782 26.5941 12.9667 26.1635 13.1439C25.7329 13.3211 25.3857 13.5738 25.1219 13.9021C24.8582 14.2252 24.7182 14.6108 24.7021 15.059H21.8196C21.8411 14.1627 22.1076 13.3758 22.6189 12.6984C23.1357 12.0157 23.8247 11.4842 24.6859 11.1038C25.5472 10.7182 26.5134 10.5254 27.5846 10.5254C28.688 10.5254 29.6462 10.726 30.459 11.1273C31.2772 11.5233 31.9096 12.0574 32.3564 12.7296C32.8032 13.4019 33.0266 14.1444 33.0266 14.9573C33.032 15.8588 32.7574 16.6144 32.203 17.2241C31.654 17.8338 30.9327 18.2324 30.0391 18.42V18.5451C31.2018 18.7014 32.0927 19.1183 32.7117 19.7957C33.3361 20.468 33.6456 21.3043 33.6402 22.3048C33.6402 23.2011 33.3765 24.0036 32.8489 24.7123C32.3268 25.4158 31.6055 25.9682 30.6851 26.3694C29.77 26.7707 28.7203 26.9713 27.5361 26.9713Z" fill="#E5953C"/>
</g>
<defs>
<filter id="filter0_i_1201_7803" x="21.4724" y="10.5254" width="12.1678" height="20.4458" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_1201_7803"/>
</filter>
<linearGradient id="paint0_linear_1201_7803" x1="45.8125" y1="11.841" x2="8.64475" y2="14.4724" gradientUnits="userSpaceOnUse">
<stop offset="0.332031" stop-color="#E1943B"/>
<stop offset="1" stop-color="#7B5120"/>
</linearGradient>
<linearGradient id="paint1_linear_1201_7803" x1="27.2918" y1="4.06152" x2="27.2918" y2="33.2725" gradientUnits="userSpaceOnUse">
<stop stop-color="#A8541C"/>
<stop offset="1" stop-color="#42210B"/>
</linearGradient>
</defs>
</svg>
`;
