export default `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none">
<g filter="url(#filter0_i_29_6973)">
<path d="M4.99973 19.8287L5.67052 15.8952C5.72562 15.5721 5.61888 15.2425 5.38484 15.0131L0.25857 9.9871C0.0251562 9.75829 -0.0587693 9.41596 0.0419407 9.10432C0.142651 8.79268 0.410561 8.56545 0.732815 8.51834L7.81239 7.48375C8.13859 7.43608 8.42033 7.23071 8.56555 6.93475L11.7326 0.479916C11.8768 0.18609 12.1743 0 12.5001 0C12.826 0 13.1236 0.18609 13.2677 0.479916L16.4348 6.93475C16.58 7.23071 16.8617 7.43608 17.1879 7.48375L24.2671 8.51834C24.5895 8.56554 24.8575 8.79268 24.9581 9.10432C25.0587 9.41596 24.9748 9.75829 24.7415 9.9871L19.6152 15.0131C19.3812 15.2425 19.2744 15.5721 19.3295 15.8952L20.54 22.9934C20.595 23.3164 20.4631 23.6429 20.1993 23.8355C19.9359 24.0282 19.5863 24.0536 19.2979 23.9011L12.9673 20.5536C12.6748 20.3989 12.3248 20.3989 12.0324 20.5536L5.70183 23.9011C5.57663 23.9674 5.43985 24 5.30344 24C5.12613 24 4.94947 23.9445 4.80026 23.8355C4.53643 23.6429 4.40456 23.3163 4.45946 22.9934L4.99973 19.8287Z" fill="url(#paint0_linear_29_6973)"/>
</g>
<path d="M5.24616 19.8708L5.24617 19.8707L5.91696 15.9373C5.98584 15.5334 5.85242 15.1214 5.55986 14.8346L0.433592 9.80859L0.433578 9.80857C0.268064 9.64632 0.208158 9.40297 0.279827 9.18119C0.351465 8.95952 0.54162 8.79895 0.768974 8.76572L7.84854 7.73112C8.25629 7.67153 8.60847 7.41482 8.78999 7.04487L11.9571 0.590039L11.9571 0.590003C12.0595 0.381311 12.2703 0.25 12.5001 0.25C12.7301 0.25 12.9409 0.381325 13.0432 0.590003L13.0432 0.590039L16.2103 7.04488C16.3919 7.41482 16.744 7.67153 17.1518 7.73112L24.2309 8.76571C24.231 8.76571 24.231 8.76571 24.231 8.76572C24.4586 8.79907 24.6487 8.95964 24.7202 9.18113C24.7918 9.40294 24.7319 9.64633 24.5665 9.80859C24.5665 9.80859 24.5664 9.8086 24.5664 9.80861L19.4402 14.8346C19.1476 15.1214 19.0142 15.5334 19.0831 15.9373L20.2936 23.0354C20.3327 23.2654 20.2386 23.4973 20.0519 23.6337L20.0518 23.6337C19.8655 23.7699 19.6185 23.7879 19.4147 23.6801L19.2979 23.9011L19.4147 23.6801L13.0842 20.3326C12.7186 20.1392 12.2811 20.1392 11.9155 20.3326L5.58496 23.6801L5.58479 23.6802C5.49649 23.727 5.40001 23.75 5.30344 23.75C5.17826 23.75 5.0534 23.7108 4.94768 23.6336L4.80026 23.8355L4.94767 23.6336C4.76096 23.4973 4.66686 23.2654 4.70589 23.0355L5.24616 19.8708ZM5.24616 19.8708L4.70592 23.0353L5.24616 19.8708Z" stroke="#FDD278" stroke-opacity="0.3" stroke-width="0.5" stroke-linejoin="round"/>
<defs>
<filter id="filter0_i_29_6973" x="0" y="0" width="25" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 0.929412 0 0 0 1 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_29_6973"/>
</filter>
<linearGradient id="paint0_linear_29_6973" x1="12" y1="11" x2="12" y2="21" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFF5A4"/>
<stop offset="1" stop-color="#FDCF73"/>
</linearGradient>
</defs>
</svg>`;
