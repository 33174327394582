export default `<svg viewBox="0 0 38 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.7496 0L0 0V24.9995H18.7496V0Z" fill="white"/>
<path d="M18.7496 0L0 0V1.92248H18.7496V0ZM18.7496 3.84495H0V5.76743H18.7496V3.84495ZM18.7496 7.6899H0V9.61238H18.7496V7.6899ZM18.7496 11.5349H0V13.4573H18.7496V11.5349ZM18.7496 15.3871H0V17.3096H18.7496V15.3871ZM18.7496 19.2321H0V21.1545H18.7496V19.2321ZM18.7496 23.077H0V24.9995H18.7496V23.077Z" fill="#D80027"/>
<path d="M18.7496 0L0 0V13.4573H18.7496V0Z" fill="#2E52B2"/>
<path d="M15.2559 10.1535L15.5483 9.21784L15.8699 10.1535H16.8348L16.0527 10.7163L16.3451 11.652L15.5483 11.0745L14.7735 11.652L15.0732 10.7163L14.2764 10.1535H15.2559ZM11.1405 10.1535L11.4402 9.21784L11.7472 10.1535H12.7121L11.9299 10.7163L12.2223 11.652L11.4402 11.0745L10.6507 11.652L10.9431 10.7163L10.161 10.1535H11.1405ZM7.01045 10.1535L7.32478 9.21784L7.61717 10.1535H8.60399L7.79991 10.7163L8.10692 11.652L7.32478 11.0745L6.5207 11.652L6.82771 10.7163L6.04556 10.1535H7.01045ZM2.90235 10.1535L3.19474 9.21784L3.50175 10.1535H4.47396L3.6845 10.7163L3.97689 11.652L3.19474 11.0745L2.40528 11.652L2.7196 10.7163L1.91553 10.1535H2.90235ZM11.4402 5.50446L11.7472 6.44012H12.7121L11.9299 7.01759L12.2223 7.93862L11.4402 7.36846L10.6507 7.93862L10.9431 7.01759L10.161 6.44012H11.1405L11.4402 5.50446ZM15.5483 5.50446L15.8699 6.44012H16.8348L16.0527 7.01759L16.3451 7.93862L15.5483 7.36846L14.7735 7.93862L15.0732 7.01759L14.2764 6.44012H15.2559L15.5483 5.50446ZM7.32478 5.50446L7.61717 6.44012H8.60399L7.79991 7.01759L8.10692 7.93862L7.32478 7.36846L6.5207 7.93862L6.82771 7.01759L6.04556 6.44012H7.01045L7.32478 5.50446ZM3.19474 5.50446L3.50175 6.44012H4.47396L3.6845 7.01759L3.97689 7.93862L3.19474 7.36846L2.40528 7.93862L2.7196 7.01759L1.91553 6.44012H2.90235L3.19474 5.50446ZM15.5483 1.80571L15.8699 2.72674H16.8348L16.0527 3.30421L16.3451 4.23256L15.5483 3.65508L14.7735 4.23256L15.0732 3.30421L14.2764 2.72674H15.2559L15.5483 1.80571ZM11.4402 1.80571L11.7472 2.72674H12.7121L11.9299 3.30421L12.2223 4.23256L11.4402 3.65508L10.6507 4.23256L10.9431 3.30421L10.161 2.72674H11.1405L11.4402 1.80571ZM7.32478 1.80571L7.61717 2.72674H8.60399L7.79991 3.30421L8.10692 4.23256L7.32478 3.65508L6.5207 4.23256L6.82771 3.30421L6.04556 2.72674H7.01045L7.32478 1.80571ZM3.19474 1.80571L3.50175 2.72674H4.47396L3.6845 3.30421L3.97689 4.23256L3.19474 3.65508L2.40528 4.23256L2.7196 3.30421L1.91553 2.72674H2.90235L3.19474 1.80571Z" fill="white"/>
<path d="M19 0.000232951L21.3438 0V10.1562H37.75V14.8437H21.3438V24.9999L19 24.9999V14.8437V10.1562V0.000232951Z" fill="#D80027"/>
<path d="M31.1332 16.8477L37.75 20.5237V16.8477H31.1332ZM23.0761 17.8844V24.9999H35.883L23.0761 17.8844Z" fill="#0052B4"/>
<path d="M27.2256 16.8479L37.75 22.6948V25.0005L23.0761 16.8479H27.2256Z" fill="#D80027"/>
<path d="M29.0917 8.15225L37.75 3.34199V8.15225H29.0917ZM23.0761 8.15225L37.75 0.000232951V2.30532L27.2256 8.15225H23.0761ZM33.8414 9.18721e-05L23.0761 5.98135V9.18721e-05H33.8414Z" fill="#0052B4"/>
<path d="M23.0761 8.15225L37.75 0.000232951V2.30532L27.2256 8.15225H23.0761Z" fill="white"/>
<path d="M23.0761 8.15225L37.75 0.000232951V2.30532L27.2256 8.15225H23.0761Z" fill="#D80027"/>
</svg>`;
