export default `<svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M24.5138 11C16.9004 11 11 16.5712 11 24.0955C11 28.0314 12.6151 31.4323 15.2445 33.7819C15.4647 33.9802 15.5979 34.2545 15.6088 34.5506L15.6822 36.9518C15.7067 37.7178 16.4979 38.2149 17.1995 37.908L19.8805 36.7264C20.1073 36.626 20.3618 36.6069 20.601 36.6721C21.8328 37.0116 23.1461 37.1909 24.5138 37.1909C32.1271 37.1909 38.0275 31.6197 38.0275 24.0954C38.0275 16.5712 32.1271 11 24.5138 11Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.4001 27.9252L20.3699 21.6342C21.0007 20.6346 22.3548 20.3847 23.301 21.0937L26.4578 23.4596C26.5989 23.565 26.7703 23.6217 26.9464 23.6212C27.1224 23.6207 27.2936 23.563 27.434 23.4569L31.6975 20.2244C32.2658 19.7925 33.0109 20.4744 32.6274 21.0774L28.6603 27.3657C28.0295 28.3653 26.6754 28.6152 25.7292 27.9062L22.5724 25.5403C22.4314 25.4349 22.2599 25.3782 22.0838 25.3787C21.9078 25.3791 21.7366 25.4368 21.5962 25.543L17.33 28.7782C16.7617 29.2101 16.0167 28.5282 16.4001 27.9252Z" fill="white"/>
<defs>
<linearGradient id="paint0_linear_729_8406" x1="5416.5" y1="11" x2="5416.5" y2="10886" gradientUnits="userSpaceOnUse">
<stop stop-color="#00B2FF"/>
<stop offset="1" stop-color="#006AFF"/>
</linearGradient>
</defs>
</svg>

`;
