<template>
  <Listbox as="div" v-slot="{ open }" :model-value="countryCodeObject" @update:model-value="handleInput" :disabled="context.disabled">
    <ListboxButton
      data-cy="countryCodeListboxButton"
      class="flex rounded-l-lg h-full items-center p-3.5 gap-4 justify-between bg-white border-r border-gray-300 w-full"
      :class="{ 'cursor-not-allowed': context.disabled }"
    >
      <p class="truncate">{{ countryCodeObject?.abbreviation || '-' }}</p>
      <FormKitIcon icon="ChevronDownIcon" class="w-4 shrink-0 transition" :class="open && 'rotate-180'" />
    </ListboxButton>

    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
      <ListboxOptions class="absolute mt-1 border flex flex-col w-full rounded-lg bg-white max-h-60 overflow-auto z-10 focus:outline-none">
        <ListboxOption
          as="template"
          v-for="option in phoneCodes"
          :key="option.abbreviation"
          :value="option"
          v-slot="{ active, selected }"
          :disabled="context.isRequired ? option.abbreviation === countryCodeObject?.abbreviation : false"
        >
          <li
            data-cy="countryCodeListboxOption"
            :class="[
              'flex cursor-pointer justify-between items-center gap-2 px-3 py-2 text-sm w-full',
              active ? 'bg-green-200 text-gray-900' : 'text-gray-700 hover:bg-green-200',
              selected && 'opacity-50 bg-green-200',
              context.isRequired && selected && '!cursor-default',
            ]"
          >
            <p class="truncate">{{ option.label }}</p>
            <p>{{ option.value }}</p>
          </li>
        </ListboxOption>
      </ListboxOptions>
    </transition>
  </Listbox>
</template>

<script setup lang="ts">
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CountryCode, phoneCodes } from '@/stores/constants';
import { FormKitIcon } from '@formkit/vue';
import { FormKitNode } from '@formkit/core';
import { ref } from 'vue';

export type PhoneCode = { label: string; value: string; abbreviation: CountryCode };

type FormkitContext = {
  node: FormKitNode<string | null>;
  disabled?: boolean;
  isRequired?: boolean;
};

const props = defineProps<{
  context: FormkitContext;
}>();

const countryCodeObject = ref<PhoneCode | null>(phoneCodes.find(code => code.abbreviation === props.context.node.value) || null);

const handleInput = (countryCodeSelected: PhoneCode) => {
  if (countryCodeSelected.abbreviation === props.context.node.value) {
    countryCodeObject.value = null;
    props.context.node.input(null);
  } else {
    countryCodeObject.value = countryCodeSelected;
    props.context.node.input(countryCodeSelected.abbreviation);
  }
};
</script>
