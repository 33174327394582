export default `<svg  viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_694_17323)">
<path d="M13.2 13.2H22.8M13.2 13.2V22.8M13.2 13.2V9.60001C13.2 7.61178 11.5882 6 9.6 6V6C7.61178 6 6 7.61177 6 9.59999V9.59999C6 11.5882 7.61177 13.2 9.59999 13.2H13.2ZM22.8 13.2V22.8M22.8 13.2V9.59999C22.8 7.61177 24.4118 6 26.4 6V6C28.3882 6 30 7.61177 30 9.59999V9.59999C30 11.5882 28.3882 13.2 26.4 13.2L22.8 13.2ZM13.2 22.8H22.8M13.2 22.8H9.6C7.61178 22.8 6 24.4118 6 26.4V26.4C6 28.3882 7.61178 30 9.60001 30V30C11.5882 30 13.2 28.3882 13.2 26.4V22.8ZM22.8 22.8L26.4 22.8C28.3882 22.8 30 24.4118 30 26.4V26.4C30 28.3882 28.3882 30 26.4 30V30C24.4118 30 22.8 28.3882 22.8 26.4V22.8Z" stroke="currentColor" stroke-width="1.5"/>
</g>
<defs>
<clipPath id="clip0_694_17323">
<rect width="34.6667" height="34.6667" fill="white" transform="translate(0.666992 0.666626)"/>
</clipPath>
</defs>
</svg>
`;
