export default `<svg viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.4329 31.7986L28.855 34.9291L17.8443 54.0001L14.6841 46.952L23.4329 31.7986Z" fill="#F9D511"/>
<path d="M23.4329 31.7986L18.0107 28.6681L7.00004 47.7391L14.6841 46.952L23.4329 31.7986Z" fill="#E3A404"/>
<path d="M31.1685 31.7986L25.7463 34.9291L36.757 54.0001L39.9173 46.952L31.1685 31.7986Z" fill="#E3A404"/>
<path d="M31.1685 31.7986L36.5906 28.6681L47.6013 47.7391L39.9173 46.952L31.1685 31.7986Z" fill="#F9D511"/>
<circle cx="27.1342" cy="18.6674" r="18.6674" fill="url(#paint0_linear_1201_7778)"/>
<circle cx="27.1344" cy="18.6673" r="14.5716" fill="#A88300"/>
<mask id="mask0_1201_7778" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="13" y="5" width="30" height="30">
<circle cx="27.9122" cy="20.223" r="14.6055" fill="url(#paint1_linear_1201_7778)"/>
</mask>
<g mask="url(#mask0_1201_7778)">
<circle cx="27.1343" cy="18.6675" r="14.6055" fill="url(#paint2_linear_1201_7778)"/>
</g>
<g filter="url(#filter0_i_1201_7778)">
<path d="M29.4526 10.4746V26.9205H26.4734V13.3735H26.377L22.5305 15.8308V13.1005L26.6179 10.4746H29.4526Z" fill="#FFF9B5"/>
</g>
<defs>
<filter id="filter0_i_1201_7778" x="22.5305" y="10.4746" width="6.92212" height="20.446" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_1201_7778"/>
</filter>
<linearGradient id="paint0_linear_1201_7778" x1="8.3869" y1="18.6976" x2="45.8836" y2="7.021" gradientUnits="userSpaceOnUse">
<stop stop-color="#DD9C01"/>
<stop offset="1" stop-color="#F8CF0F"/>
</linearGradient>
<linearGradient id="paint1_linear_1201_7778" x1="27.9122" y1="5.61743" x2="27.9122" y2="34.8285" gradientUnits="userSpaceOnUse">
<stop stop-color="#EFAE05"/>
<stop offset="1" stop-color="#896403"/>
</linearGradient>
<linearGradient id="paint2_linear_1201_7778" x1="27.1343" y1="4.06201" x2="27.1343" y2="33.2731" gradientUnits="userSpaceOnUse">
<stop stop-color="#EFAE05"/>
<stop offset="1" stop-color="#896403"/>
</linearGradient>
</defs>
</svg>
`;
