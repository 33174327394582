import posthog from 'posthog-js';
import { App } from 'vue';
import { envs } from '@/env';

export default {
  install(app: App<Element>) {
    app.config.globalProperties.$posthog = posthog.init(envs.VITE_POSTHOG_API_KEY, {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'identified_only',
      autocapture: false,
    });
  },
};
