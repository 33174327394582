import mitt, { Emitter } from 'mitt';

export interface ToastOptions {
  title: string;
  type: 'err' | 'warn' | 'confirm' | '';
  body: string | string[];
  timeout?: number;
  actionButton?: {
    callback: () => void;
    label: string;
  };
}
export interface Toast {
  close: () => void;
  show: (_options: ToastOptions) => void;
}
export type ToastAPI = (msg: string | string[], options?: Partial<ToastOptions>) => void;

type Events = {
  newToast: ToastOptions;
};

export const emitter: Emitter<Events> = mitt<Events>();

export const toast: ToastAPI = (msg: string | string[], options: Partial<ToastOptions> = {}) => {
  if (typeof msg !== 'string') {
    options.body = msg;
  } else {
    options.title = msg;
  }

  emitter.emit('newToast', { body: '', title: '', type: '', ...options });
};
