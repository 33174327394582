export default `<svg width="68" height="95" viewBox="0 0 68 95" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_5480_64086)">
<rect x="30.0688" y="47.9785" width="4.08084" height="14.3903" fill="url(#paint0_linear_5480_64086)"/>
<path d="M14.586 17.5996H2C2 27.3597 2.74849 32.9739 16.6544 32.9739" stroke="#FFDD66" stroke-width="2.80683"/>
<path d="M50.5127 30.3837C50.5127 40.5919 42.2373 48.8673 32.0291 48.8673C21.8208 48.8673 13.5454 40.5919 13.5454 30.3837C13.5454 20.1754 21.8208 11.9 32.0291 11.9C42.2373 11.9 50.5127 20.1754 50.5127 30.3837Z" fill="#FFDD66"/>
<path d="M32.0291 61.6504C24.4921 61.6504 18.3823 63.8546 18.3823 66.5736H45.6759C45.6759 63.8546 39.566 61.6504 32.0291 61.6504Z" fill="#FFDD66"/>
<path d="M13.5454 8.34766H50.5127V29.5199H13.5454V8.34766Z" fill="#FFDD66"/>
<path d="M45.6759 67.5237H18.3823V66.4872H45.6759V67.5237Z" fill="#FFDD66"/>
<path d="M49.9897 17.5996H62.5757C62.5757 27.3597 61.8272 32.9739 47.9213 32.9739" stroke="#DE9300" stroke-width="2.80683"/>
<path d="M50.5124 30.3823C50.5124 40.5905 42.237 48.8659 32.0288 48.8659C32.0288 48.8659 32.0288 40.5905 32.0288 30.3823C32.0288 20.174 32.0288 11.8986 32.0288 11.8986C42.237 11.8986 50.5124 20.174 50.5124 30.3823Z" fill="url(#paint1_linear_5480_64086)"/>
<path d="M32.0288 61.649C32.0288 61.649 32.0288 63.8532 32.0288 66.5722H45.6756C45.6756 63.8532 39.5657 61.649 32.0288 61.649Z" fill="url(#paint2_linear_5480_64086)"/>
<path d="M32.0288 8.34766H50.5124V29.5185H32.0288V8.34766Z" fill="url(#paint3_linear_5480_64086)"/>
<path d="M45.6756 67.5223H32.0288C32.0288 67.5223 31.9981 66.8894 32.0288 66.4858C32.4324 61.1717 45.6756 66.4858 45.6756 66.4858V67.5223Z" fill="url(#paint4_linear_5480_64086)"/>
<circle cx="32.3918" cy="28.3249" r="11.0612" fill="#DCAE0C"/>
<circle cx="32.3695" cy="28.1889" r="13.1877" fill="#DE9300" stroke="#FFE176" stroke-width="1.42037"/>
<path d="M32.3697 19.0957L35.1722 24.7006L40.7771 25.4012L36.9284 29.717L37.9746 35.9104L32.3697 33.1079L26.7648 35.9104L27.8204 29.717L23.9624 25.4012L29.5673 24.7006L32.3697 19.0957Z" fill="#FFF4BC"/>
<path d="M32.6487 18.9562L32.3697 18.3983L32.0908 18.9562L29.363 24.4118L23.9237 25.0917L23.3334 25.1655L23.7299 25.6091L27.4877 29.8128L26.4574 35.858L26.354 36.4645L26.9043 36.1893L32.3697 33.4566L37.8352 36.1893L38.3844 36.464L38.2821 35.8584L37.2609 29.8127L41.0098 25.6088L41.4052 25.1654L40.8158 25.0917L35.3765 24.4118L32.6487 18.9562Z" stroke="#C98500" stroke-opacity="0.7" stroke-width="0.62374"/>
<path d="M9.4996 12.8684L11.4486 8.57892L9.4996 4.28946L13.7891 6.23848L18.0785 4.28946L16.1295 8.57892L18.0785 12.8684L13.7891 10.9194L9.4996 12.8684Z" fill="white"/>
<path d="M12.7886 71.4279C12.7886 68.8131 14.9083 66.6934 17.5231 66.6934H46.7702C49.385 66.6934 51.5048 68.8131 51.5048 71.4279V86.5255H12.7886V71.4279Z" fill="#243B6D"/>
<rect x="18.7393" y="72.2085" width="27.6034" height="9.41611" fill="#F9B50E" stroke="#FFEC86" stroke-width="1.22175"/>
<path d="M20.1875 71.5977H33.2275L43.5222 82.2355H30.4822L20.1875 71.5977Z" fill="#FFEC86"/>
<rect x="10.6372" y="86.5254" width="43.018" height="4.11788" fill="#DE9300"/>
</g>
<defs>
<filter id="filter0_d_5480_64086" x="0.35233" y="4.04471" width="67.2919" height="90.2631" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="1.71045" dy="1.71045"/>
<feGaussianBlur stdDeviation="0.977399"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5480_64086"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5480_64086" result="shape"/>
</filter>
<linearGradient id="paint0_linear_5480_64086" x1="32.1093" y1="47.9785" x2="32.1093" y2="62.3688" gradientUnits="userSpaceOnUse">
<stop stop-color="#C07F00"/>
<stop offset="1" stop-color="#DE9300"/>
</linearGradient>
<linearGradient id="paint1_linear_5480_64086" x1="50.3852" y1="26.9874" x2="31.8547" y2="26.9874" gradientUnits="userSpaceOnUse">
<stop stop-color="#DE9300"/>
<stop offset="1" stop-color="#FFBC11"/>
</linearGradient>
<linearGradient id="paint2_linear_5480_64086" x1="50.3852" y1="26.9874" x2="31.8547" y2="26.9874" gradientUnits="userSpaceOnUse">
<stop stop-color="#DE9300"/>
<stop offset="1" stop-color="#FFBC11"/>
</linearGradient>
<linearGradient id="paint3_linear_5480_64086" x1="50.3852" y1="26.9874" x2="31.8547" y2="26.9874" gradientUnits="userSpaceOnUse">
<stop stop-color="#DE9300"/>
<stop offset="1" stop-color="#FFBC11"/>
</linearGradient>
<linearGradient id="paint4_linear_5480_64086" x1="50.3852" y1="26.9874" x2="31.8547" y2="26.9874" gradientUnits="userSpaceOnUse">
<stop stop-color="#DE9300"/>
<stop offset="1" stop-color="#FFBC11"/>
</linearGradient>
</defs>
</svg>`;
