import { AxiosResponse } from 'axios';
import http, { useAxiosUtils } from './axiosClient';
import { UserDTO } from './userDataService';
import { AccountLanguage } from './useLanguageService';
import { useTenantService } from './tenantService';

interface AdminLoginResponse {
  id: number;
  locale: AccountLanguage;
  createdAt: Date;
  updatedAt: Date;
}

export interface LoginCredentials {
  identifier: string;
  password: string;
}

export interface RegistrationCredentials {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  password: string;
  tenant: number;
  accountLanguage: AccountLanguage;
}
export interface ForgotPasswordInterface {
  email: string;
}

export interface ResetPasswordInterface {
  code: string;
  password: string;
  passwordConfirmation: string;
}

interface AuthStrapiResponse extends AxiosResponse<UserDTO> {
  jwt?: string;
  user?: UserDTO;
  meta?: {
    pagination?: {
      limit: number;
      start: number;
      total: number;
    };
  };
}

export default function useAuthService() {
  const { getHeaders } = useAxiosUtils();
  const { getTenantId } = useTenantService();

  function login(data: LoginCredentials) {
    return http.post<AuthStrapiResponse>('/api/auth/local', data);
  }
  function register(data: RegistrationCredentials) {
    return http.post<AuthStrapiResponse>('/api/auth/local/register', data);
  }
  function forgotPassword(data: ForgotPasswordInterface) {
    return http.post('/api/auth/forgot-password', data);
  }
  function resetPassword(data: ResetPasswordInterface) {
    return http.post('/api/auth/reset-password', data);
  }
  function resendVerificationEmail(data: string) {
    return http.post('/api/auth/send-email-confirmation', { email: data });
  }
  async function adminLogin(locale: AccountLanguage) {
    return http.post<AdminLoginResponse>(`/api/logged-in-admin/handleAdminLogin/${getTenantId()}/${locale}`, {}, getHeaders());
  }
  async function handleHeartbeat(locale: AccountLanguage) {
    return http.put<AdminLoginResponse>(`/api/logged-in-admin/updateLoggedInAdminStatus/${getTenantId()}/${locale}`, {}, getHeaders());
  }
  async function deleteAdminSession(locale: AccountLanguage) {
    return http.delete<AdminLoginResponse>(`/api/logged-in-admin/deleteLoggedInAdmin/${getTenantId()}/${locale}`, getHeaders());
  }
  return {
    login,
    register,
    forgotPassword,
    resetPassword,
    resendVerificationEmail,
    adminLogin,
    handleHeartbeat,
    deleteAdminSession,
  };
}
