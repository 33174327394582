export default `<svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_305_1319)">
<path d="M18.5876 7.92346H6.41272C6.14189 7.92346 5.92236 8.14299 5.92236 8.41382V18.6234C5.92236 18.8943 6.14189 19.1138 6.41272 19.1138H8.58321V22.5545C8.58321 23.3528 9.23036 24 10.0287 24C10.827 24 11.4742 23.3528 11.4742 22.5545V19.1138H13.496V22.5545C13.496 23.3528 14.1432 24 14.9415 24C15.7398 24 16.387 23.3528 16.387 22.5545V19.1138H18.5877C18.8585 19.1138 19.078 18.8943 19.078 18.6234V8.41382C19.078 8.14299 18.8584 7.92346 18.5876 7.92346Z" fill="#AAC148"/>
<path d="M3.86103 7.92114C3.06273 7.92114 2.41553 8.56829 2.41553 9.36665V15.2377C2.41553 16.036 3.06267 16.6832 3.86103 16.6832C4.65933 16.6832 5.30654 16.0361 5.30654 15.2377V9.36665C5.30648 8.56835 4.65933 7.92114 3.86103 7.92114Z" fill="#AAC148"/>
<path d="M21.1394 7.92114C20.3411 7.92114 19.6938 8.56829 19.6938 9.36665V15.2377C19.6938 16.036 20.341 16.6832 21.1394 16.6832C21.9377 16.6832 22.5849 16.0361 22.5849 15.2377V9.36665C22.5849 8.56835 21.9377 7.92114 21.1394 7.92114Z" fill="#AAC148"/>
<path d="M6.51137 7.20745H18.4605C18.7722 7.20745 19.0047 6.9203 18.9406 6.6152C18.5454 4.73139 17.346 3.14384 15.7186 2.22674L16.7401 0.383656C16.8092 0.258874 16.7641 0.101618 16.6393 0.0324888C16.5143 -0.0368151 16.3572 0.00843866 16.2881 0.133221L15.2589 1.99019C14.4159 1.599 13.4764 1.38052 12.4859 1.38052C11.4953 1.38052 10.5559 1.599 9.71289 1.99019L8.68367 0.133162C8.61448 0.00832248 8.45723 -0.0366989 8.3325 0.0324307C8.20772 0.10156 8.16264 0.258816 8.23177 0.383597L9.25326 2.22669C7.62581 3.14384 6.42644 4.73133 6.03118 6.6152C5.96711 6.9203 6.19959 7.20745 6.51137 7.20745ZM16.0514 4.46068C16.0514 4.76752 15.8026 5.01633 15.4957 5.01633C15.1889 5.01633 14.9401 4.76758 14.9401 4.46068C14.9401 4.15383 15.1888 3.90503 15.4957 3.90503C15.8026 3.90503 16.0514 4.15383 16.0514 4.46068ZM9.47599 3.90503C9.78283 3.90503 10.0316 4.15378 10.0316 4.46068C10.0316 4.76752 9.78289 5.01633 9.47599 5.01633C9.16914 5.01633 8.92034 4.76758 8.92034 4.46068C8.92034 4.15383 9.16909 3.90503 9.47599 3.90503Z" fill="#AAC148"/>
</g>
<defs>
<clipPath id="clip0_305_1319">
<rect width="24" height="24" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
</svg>
`;
